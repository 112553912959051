import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Typography } from 'antd';
import { Div, Button } from '../../framework';
import { closeModal } from '../../store/app.slice';
import { useForm } from 'antd/lib/form/Form';
import { RootState } from '../../store/store';
import { paymentMethods, banks } from '../../../constant/payment';
import { setPartialTalentProfile } from '../../store/user.slice';
import { useTalentService } from '../../service/talent.service';

const { Title, Paragraph } = Typography;
const { Option } = Select;

type FormValue = {
  beneficieryName: string;
  phoneNumber: string;
};

const ChangePaymentMethodModal: FC = () => {
  const dispatch = useDispatch();
  const talentService = useTalentService();
  const [form] = useForm();
  const { talent } = useSelector((state: RootState) => state.user);
  const [method, setMethod] = useState<string>('MY_DUITNOW');

  const handleSubmitForm = async ({
    beneficieryName,
    phoneNumber,
  }: FormValue) => {
    if (!talent.id) return;

    await talentService.updateProfile(
      {
        paymentMethod: { beneficieryName, channelCode: method },
        phoneNumber,
      },
      talent.id
    );
    dispatch(
      setPartialTalentProfile({
        paymentMethod: { beneficieryName, channelCode: method },
        phoneNumber,
      })
    );
    dispatch(closeModal());
  };

  return (
    <Div className='talent-change-payment-method-modal'>
      <Title level={4} style={{ textAlign: 'center' }}>
        Change payment method
      </Title>
      <Div mb='l' style={{ textAlign: 'center' }}>
        <Paragraph style={{ color: 'black' }}>
          This will also be saved as the default payment method for future jobs
        </Paragraph>
      </Div>
      <Div mv='xxl'>
        <Div mb='xs' style={{ fontWeight: 600 }}>
          Select Method
        </Div>
        <Select
          value={method}
          onChange={setMethod}
          style={{ display: 'block' }}
        >
          {Object.keys(paymentMethods).map((key) => (
            <Option key={key} value={key}>
              {paymentMethods[key as keyof typeof paymentMethods]}
            </Option>
          ))}
        </Select>
      </Div>

      <Form form={form} layout='vertical' onFinish={handleSubmitForm}>
        {method === 'MY_DUITNOW' && (
          <>
            <Form.Item
              name='beneficieryName'
              label={<span style={{ fontWeight: 600 }}>Beneficiery Name</span>}
              rules={[
                {
                  required: true,
                  message: 'Please input beneficiery name.',
                },
              ]}
              initialValue={
                talent.paymentMethod?.beneficieryName || talent.name
              }
            >
              <Input placeholder='Beneficiery Name' />
            </Form.Item>
            <Form.Item
              name='phoneNumber'
              label={<span style={{ fontWeight: 600 }}>Mobile Number</span>}
              rules={[
                {
                  required: true,
                  message: 'Please input a valid phone number',
                },
                {
                  pattern: /^(0?1)[0-46-9]-*[0-9]{7,8}$/,
                  message: "This phone number doesn't seems right",
                },
              ]}
              style={{ marginBottom: 0 }}
              {...(talent.phoneNumberPrefix === '+60' && {
                initialValue: talent.phoneNumber,
              })}
            >
              <Input type='number' addonBefore='+60' />
            </Form.Item>
          </>
        )}
        {/* {method === 'bank' && (
          <>
            <Form.Item
              name='paymentBank'
              label={<span style={{ fontWeight: 600 }}>Select Bank</span>}
              rules={[
                {
                  required: true,
                  message: 'Please specify a bank',
                },
              ]}
            >
              <Select placeholder='Please specify a bank'>
                {Object.keys(banks).map((key) => (
                  <Option key={key} value={key}>
                    {banks[key as keyof typeof banks]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='paymentBankAccount'
              label={
                <span style={{ fontWeight: 600 }}>Bank Account Number</span>
              }
              rules={[
                {
                  required: true,
                  message: 'Please specify your account number',
                },
              ]}
            >
              <Input placeholder='Bank account number' />
            </Form.Item>
          </>
        )} */}
        <Form.Item style={{ marginTop: '3rem' }}>
          <Button type='submit' block solid style={{ marginBottom: '0.75rem' }}>
            Save
          </Button>
          <Button
            type='button'
            block
            ghost
            onClick={() => dispatch(closeModal())}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Div>
  );
};

export default ChangePaymentMethodModal;
