import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { isClient } from './detect';

export const useResetScroll = () => {
  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
    });
  }, []);
};

export const useClientWindow = () => {
  if (isClient) return window;
};
