import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Typography } from 'antd';
import { BellOutlined, MenuOutlined } from '@ant-design/icons';
import { RootState } from '../../store/store';
import { Div } from '../Div/Div';

import './MenuBar.scss';

const { Text } = Typography;

type Props = {
  isShrank: boolean;
  onMenuClick: () => void;
  onNotificationClick: () => void;
  isOnboarded: boolean;
  isTalentLoggedIn: boolean;
  isHirerLoggedIn: boolean;
  logoRedirectUrl: string;
  isMobile?: boolean;
};

const MenuBar: FunctionComponent<Props> = ({
  isShrank,
  onMenuClick,
  onNotificationClick,
  isOnboarded,
  isTalentLoggedIn,
  isHirerLoggedIn,
  logoRedirectUrl,
  isMobile,
}) => {
  const location = useLocation();
  const { profilePictureUrl, activities } = useSelector(
    (state: RootState) => state.user
  );
  const { companyProfile } = useSelector((state: RootState) => state.hirer);

  const getProfilePic = () => {
    if (isTalentLoggedIn && !location.pathname.startsWith('/hirer')) {
      return (
        <>
          <Div className='burger-menu' noGrow>
            <button onClick={onNotificationClick}>
              <Badge count={activities.pending?.length || 0} offset={[0, 24]}>
                <BellOutlined className='notification-icon' />
              </Badge>
            </button>
          </Div>
          <Div className='profile-menu' noGrow>
            <button onClick={onMenuClick}>
              <img src={profilePictureUrl} />
            </button>
          </Div>
        </>
      );
    }

    if (isHirerLoggedIn && companyProfile.profilePic) {
      return (
        <Div className='profile-menu' noGrow>
          <button onClick={onMenuClick}>
            <img src={companyProfile.profilePic} />
          </button>
        </Div>
      );
    }
  };

  return (
    <Div flex className={`component-menu ${isShrank ? '--shrank' : ''}`.trim()}>
      <Div>
        {isTalentLoggedIn && !isOnboarded ? (
          <Div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              className='logo'
              src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo.png'
              alt='heycast.me logo'
            />
            <Text className='name'>HEYCAST.ME</Text>
          </Div>
        ) : (
          <Link
            to={logoRedirectUrl}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              className='logo'
              src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo.png'
              alt='heycast.me logo'
            />
            <Text className='name'>HEYCAST.ME</Text>
          </Link>
        )}
      </Div>
      {getProfilePic() ?? (
        <Div className='burger-menu' noGrow>
          <button onClick={onMenuClick}>
            <MenuOutlined style={isMobile ? { color: 'black' } : {}} />
          </button>
        </Div>
      )}
    </Div>
  );
};

export { MenuBar };
