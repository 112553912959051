import React, { useState, useEffect, useRef, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography, message } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Div } from '../../framework';
import { TALENT_ROUTES } from '../../route/constant';
import { setBannerDismissed } from '../../store/app.slice';
import { setLastVerifyEmailResendTime } from '../../store/user.slice';
import { RootState } from '../../store/store';
import { useAuthService } from '../../service/auth.service';
import { getEmailProviderUrl } from '../../helper/getEmailProviderUrl';

import './OnboardingBanner.scss';

const { Link: AntdLink } = Typography;

const OnboardingBanner: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authService = useAuthService();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { authUser, talent, settings, lastVerifyEmailResendTime, activities } =
    useSelector((state: RootState) => state.user);
  const { isBannerDismissed } = useSelector((state: RootState) => state.app);

  const [isShowBanner, setShowBanner] = useState(true);
  const emailLink =
    authUser?.email &&
    getEmailProviderUrl(authUser.email, 'verify your account');
  const [allowResendEmail, setAllowResendEmail] = useState<boolean>(true);

  const dismissBanner = (isDismissed: boolean) => {
    dispatch(setBannerDismissed({ isDismissed }));
  };

  const handleResendVerifyEmail = async () => {
    const minutes = (m: number) => m * 60 * 1000;
    const allowResend =
      !lastVerifyEmailResendTime ||
      new Date().getTime() - new Date(lastVerifyEmailResendTime).getTime() >
        minutes(1);

    if (allowResend) {
      await authService.resendVerificationEmail(authUser.email);
      dispatch(
        setLastVerifyEmailResendTime({
          lastVerifyEmailResendTime: new Date().toISOString(),
        })
      );

      setAllowResendEmail(false);
      setTimeout(() => setAllowResendEmail(true), minutes(1));
    } else {
      message.warn(
        `We've recently just sent you a verification email to ${authUser.email}. If you still haven't receive the email. please try again 1 minute later.`,
        10
      );
    }
  };

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const renderBannerContent = () => {
    if (
      activities.pending?.length > 0 &&
      !location.pathname.startsWith('/talent/activity')
    ) {
      return (
        <Div>
          There are some pending reply items that need your attention.{' '}
          <AntdLink onClick={() => history.push(TALENT_ROUTES.AGENDA)}>
            Reply now.
          </AntdLink>
        </Div>
      );
    }

    if (!authUser.verified) {
      return (
        <Div>
          We&apos;ve sent a verification email to your inbox. Please{' '}
          {emailLink ? (
            <AntdLink href={emailLink} target='_blank'>
              verify your email address
            </AntdLink>
          ) : (
            'verify your email address'
          )}{' '}
          to enjoy full experience of our services. Not received?{' '}
          <AntdLink
            onClick={handleResendVerifyEmail}
            disabled={!allowResendEmail}
          >
            Resend verification email
          </AntdLink>
          .
        </Div>
      );
    }

    const isCastingVideoUploaded =
      talent.gallery && talent.gallery.some((item) => item.type === 'video');

    if (!isCastingVideoUploaded) {
      return (
        <Div>
          95% of the talents selected for Main and Featured role have a casting
          video.🤩{' '}
          <Link to='/talent/profile/video?edit=true'>
            Upload your casting video now
          </Link>{' '}
          to stand out from the crowd and land your dream role !🚀
        </Div>
      );
    }

    if (talent.reviewSocialLinks) {
      return (
        <Div>
          You&apos;ve previously provided one or more incorrect social profile
          link(s), please{' '}
          <Link to='/talent/profile/contact?edit=true&update-social=true'>
            update your social profiles
          </Link>{' '}
          information.
        </Div>
      );
    }

    if (!settings.hasActivateTelegram) {
      return (
        <Div>
          <Link to='/instructions/activate-telegram'>
            Activate Telegram notifications
          </Link>{' '}
          and get real-time job invites! 😎🔥 Don&apos;t miss out on amazing
          opportunities tailored just for you! 😉
        </Div>
      );
    }

    return null;
  };

  if (!isBannerDismissed && renderBannerContent()) {
    return (
      <Div
        ph='s'
        pb='s'
        noGrow
        className={`component-onboarding-banner ${
          isShowBanner ? '' : '--hide'
        }`}
      >
        <Div
          noGrow
          flex
          p='s'
          className={`banner-wrapper ${isShowBanner ? '' : '--hide'}`}
        >
          {renderBannerContent()}
          <Divider className='divider' type='vertical' />
          <CloseOutlined
            onClick={() => {
              setShowBanner(false);
              // let animation finish before removing from DOM
              timeoutRef.current = setTimeout(() => dismissBanner(true), 500);
            }}
            style={{
              marginRight: '5px',
            }}
          ></CloseOutlined>
        </Div>
      </Div>
    );
  }

  return <></>;
};

export default OnboardingBanner;
