import React from 'react';

const DouyinSvg = () => (
  <svg version='1.1' viewBox='0 0 50.076 22.845' width='40' height='40'>
    <g transform='translate(-36.223 -6.1025)' fill='#111'>
      <path d='m41.947 6.1025h-2.6483v3.889h-3.0761v2.6469h3.0761v5.3957l-3.0761 0.4479v2.7895l3.0761-0.448v4.622c0 0.3665-0.3056 0.6719-0.6723 0.6719h-2.2205v2.6469h3.3206c1.2223 0 2.2205-0.9977 2.2205-2.2193v-6.1083l2.6891-0.4072v-2.7895l-2.6891 0.4072v-4.9884h2.6891v-2.6469h-2.6891z' />
      <path d='m56.945 6.1025h-2.628v13.825l-8.7192 0.7533v2.7895l8.7192-0.733v6.2101h2.628v-6.4544l2.6891-0.2444v-2.7894l-2.6891 0.2443z' />
      <path d='m52.951 9.1784-6.2542-1.1199v2.8098l6.2542 1.0995z' />
      <path d='m52.951 14.98-6.2542-1.1198v2.8098l6.2542 1.1198z' />
      <path d='m81.124 13.82 0.4074-2.1583h-2.7909l-0.4278 2.1583h-7.2932l-0.326-2.1583h-2.8113l0.3463 2.1583h-5.2967v2.5858h23.367v-2.5858z' />
      <path d='m85.565 7.935h-8.8618l-0.4482-1.8325h-3.4428l0.4481 1.8325h-9.5952v2.5655h21.9z' />
      <path d='m81.959 18.301h-14.688c-1.2224 0-2.2206 0.9977-2.2206 2.2193v6.0473c0 1.2216 0.9982 2.2193 2.2206 2.2193h14.688c1.2223 0 2.2206-0.9977 2.2206-2.2193v-6.0473c0-1.2216-0.9779-2.2193-2.2206-2.2193zm-13.751 2.4026h12.834c0.3667 0 0.6722 0.3054 0.6722 0.6719v1.0791h-14.158v-1.0791c-0.0204-0.3869 0.2852-0.6719 0.6519-0.6719zm12.834 5.7011h-12.834c-0.3667 0-0.6723-0.3054-0.6723-0.6719v-1.0995h14.159v1.0995c0 0.3665-0.2852 0.6719-0.6519 0.6719z' />
    </g>
  </svg>
);

export default DouyinSvg;
