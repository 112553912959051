import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { StarOutlined } from '@ant-design/icons';
import { Div } from '../../framework';
import { closeModal } from '../../store/app.slice';
import { useHistory } from 'react-router-dom';
import { HIRER_ROUTES } from '../../route/constant';

const { Title } = Typography;

const PromptHirerRateTalentModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRateNow = () => {
    closeSelf();
    history.push(`${HIRER_ROUTES.DASHBOARD}?tab=rate`);
  };

  const closeSelf = () => {
    dispatch(closeModal());
  };

  return (
    <Div className='delete-hirer-user-modal'>
      <Div style={{ textAlign: 'center' }} pt='m'>
        <Title level={3}>
          <Div>
            <StarOutlined
              style={{
                fontSize: 'xx-large',
                color: '#574DD3',
                strokeWidth: '10',
                stroke: '#574DD3',
              }}
            />
          </Div>
          Your Opinion Matters!
        </Title>
      </Div>

      <Paragraph style={{ textAlign: 'center' }}>
        Share with us your experience with the talents you recently hired.
      </Paragraph>

      <Div ph='xl' mt='xxl'>
        <Button type='primary' size='large' block onClick={handleRateNow}>
          Rate now
        </Button>
        <Button
          type='link'
          size='large'
          block
          onClick={closeSelf}
          style={{ marginTop: '1rem' }}
        >
          Remind me later
        </Button>
      </Div>
    </Div>
  );
};

export default PromptHirerRateTalentModal;
