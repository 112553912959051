import React, { FC, useState, useEffect } from 'react';
import { Div } from '../../framework';
import { GalleryItem } from '../../helper/type';

import './ProfilePhotoPicker.scss';

type Props = {
  defaultValue?: number;
  gallery?: GalleryItem[];
  onChange?: (item: GalleryItem) => void;
};

const ProfilePhotoPicker: FC<Props> = ({
  defaultValue = 0,
  gallery = [],
  onChange,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultValue);
  const blankSpaces = gallery.length % 3;

  useEffect(() => {
    console.log('defaultValue ==>', defaultValue);
    defaultValue && handleChange(defaultValue);
  }, [defaultValue]);

  const handleChange = (index: number) => {
    setSelectedIndex(index);
    onChange && onChange(gallery[index]);
  };

  return (
    <Div className='component-profile-photo-picker'>
      <Div flex className='gallery-preview'>
        {gallery.map((item, index) => (
          <Div
            key={index}
            className={`image-container ${
              selectedIndex === index ? '--selected' : ''
            }`.trim()}
          >
            <img src={item.original} onClick={() => handleChange(index)} />
          </Div>
        ))}
        {new Array((blankSpaces === 0 ? 0 : 3) - blankSpaces)
          .fill(1)
          .map((value, index) => (
            <Div className='image-container' key={index}>
              <Div className='image-placeholder' />
            </Div>
          ))}
      </Div>
    </Div>
  );
};

export default ProfilePhotoPicker;
