import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import { Div } from '../../framework';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';
import { useJobService } from '../../service/job.service';
import { Project } from '../../helper/type';
import { removeProject } from '../../store/hirer.slice';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;

type ModalContext = { project: Project };

const DeleteProjectModal: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jobService = useJobService();
  const { modal } = useSelector((state: RootState) => state.app);
  const { context } = modal.config as {
    context: ModalContext;
  };

  const { project } = context;

  const handleDeleteProject = async () => {
    await jobService.deleteProject({ projectId: project.id });
    dispatch(removeProject(project.id));

    history.push('/hirer/home');
    dispatch(closeModal());
  };

  return (
    <Div className='hirer-delete-project-modal'>
      <Title level={4}>Confirm delete project?</Title>
      <Div mb='l'>
        <Div>
          Are you sure you want to delete project{' '}
          <span style={{ fontWeight: 600 }}>{project.title}</span>?
        </Div>
      </Div>
      <Div mb='m'>
        <Button
          block
          type='primary'
          danger
          onClick={handleDeleteProject}
          style={{ marginBottom: '0.75rem' }}
        >
          Confirm delete
        </Button>
        <Button block onClick={() => dispatch(closeModal())}>
          No, let me reconsider
        </Button>
      </Div>
    </Div>
  );
};

export default DeleteProjectModal;
