import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

type Props = {
  initLoading?: boolean;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  render: (
    setTriggerElement: (element?: HTMLElement) => void
  ) => React.ReactNode;
  loadingIndicator?: React.ReactNode;
  endingIndicator?: React.ReactNode;
};

// TODO: use virtual list
export const InfiniteScroll: FunctionComponent<Props> = ({
  initLoading = false,
  hasMore,
  loadMore,
  render,
  loadingIndicator,
  endingIndicator,
}) => {
  const [triggerElement, setTriggerElement] = useState<HTMLElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [observer, setObserver] = useState<IntersectionObserver>();

  useEffect(() => {
    if (initLoading) {
      (async () => {
        setIsLoading(true);
        await onLoadMore();
        setIsLoading(false);
      })();
    }
  }, []);

  const onLoadMore = useCallback(async () => {
    if (hasMore && !isLoading) {
      setIsLoading(true);
      await loadMore();
      setIsLoading(false);
    }
  }, [hasMore, isLoading, loadMore]);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entry) => {
      const currentLastItem = entry[0];
      if (currentLastItem.isIntersecting) {
        onLoadMore();
      }
    }, opts);

    setObserver(observer);

    return () => observer.disconnect();
  }, [onLoadMore]);

  useEffect(() => {
    if (observer && triggerElement) {
      observer.disconnect();
      observer.observe(triggerElement);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, triggerElement]);

  return (
    <>
      {render(setTriggerElement)}
      {isLoading && loadingIndicator}
      {!hasMore && endingIndicator}
    </>
  );
};
