import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { startLoading, endLoading, setError } from '../store/app.slice';
import { setAuthUser } from '../store/user.slice';
import store from '../store/store';
import { isClient } from './detect';
import { serviceDomain } from '../../config/app.config';
import { History } from 'history';
import { nanoid } from 'nanoid';

const { auth: authUrl, monitor: monitorUrl } = serviceDomain;

type UserType = 'talent' | 'hirer';

const tokenRefresh = (
  userType: UserType,
  params: AxiosRequestConfig['params']
) =>
  axios.get(`${authUrl}/${userType}/token/refresh`, {
    withCredentials: true,
    params,
  });

const logAction = (data: any) =>
  isClient &&
  axios.post(`${monitorUrl}/rq`, data, {
    withCredentials: true,
  });

export interface InterceptorConfig {
  requestKey: string;
  isInterruptive?: boolean;
  checkTokenValidity?: boolean;
  feedback?: {
    loading?: string | boolean;
    success?: ((data: any) => string) | string | boolean;
    failure?: string | boolean;
  };
}

export type ExtendedAxiosRequestConfig<T = any> = AxiosRequestConfig<T> &
  InterceptorConfig;

const dispatch = (action: any) => {
  isClient && store.dispatch(action);
};

const useAxios = () => {
  const history = isClient && useHistory();

  return interceptedAxios(history);
};

const interceptor = async <T = any>(
  request: (...args: any[]) => Promise<AxiosResponse>,
  url: string,
  history: History | false,
  config: ExtendedAxiosRequestConfig,
  body?: any
): Promise<T> => {
  const {
    requestKey,
    isInterruptive,
    feedback,
    checkTokenValidity,
    ...axiosConfig
  } = config;

  const reqId = nanoid();

  logAction({
    type: 'request-begin',
    reqId,
    request: {
      url,
      body: {
        ...body,
        ...(body?.password ? { password: '⬤⬤⬤⬤⬤⬤⬤⬤' } : {}),
      },
      config: axiosConfig,
    },
    location: history && history.location,
  });
  dispatch(startLoading({ requestKey, isInterruptive }));
  if (isClient && feedback?.loading) {
    message.loading({
      content:
        typeof feedback.loading === 'string' ? feedback.loading : 'Loading...',
      key: requestKey,
    });
  }

  const { user } = store.getState();
  const { exp, type = 'talent', username } = user.authUser;
  const { impersonateId, impersonateKey } = user.impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  try {
    if (exp && checkTokenValidity && !isImpersonator) {
      const hasExpired =
        new Date().toISOString() > new Date((exp - 120) * 1000).toISOString();

      if (hasExpired) {
        try {
          const { data } = await tokenRefresh(type, { username, url });
          store.dispatch(setAuthUser({ ...data, type, remember: true }));
        } catch (err) {
          history && history.replace(`/auth/${type}/logout`);
          message.error({
            content: 'Session expired. Please try to re-login.',
          });
          throw err;
        }
      }
    }

    if (isImpersonator) {
      axiosConfig.headers = {
        ...(axiosConfig.headers ? axiosConfig.headers : {}),
        impersonateId,
        impersonateKey,
      };
    }

    const payload = [url, body, axiosConfig].filter(Boolean);
    const { data } = await request(...payload);

    logAction({
      type: 'request-success',
      reqId,
    });

    if (feedback?.success) {
      let content = 'Success';

      switch (typeof feedback.success) {
        case 'function':
          content = feedback.success(data) as string;
          break;
        case 'string':
          content = feedback.success as string;
          break;
      }

      message.success({
        content,
        key: requestKey,
      });
    }

    return data as T;
  } catch (err) {
    const error = err as AxiosError;
    logAction({
      type: 'request-failed',
      reqId,
      error: error,
    });

    store.dispatch(setError({ requestKey, error: error.response?.data }));

    let errorContent;

    if (error.response?.data?.code === 'UNAUTHORIZED') {
      history && history.replace(`/auth/${type}/logout`);
      errorContent = 'Not authorized to perform request, logging out';
    } else if (typeof feedback?.failure === 'string') {
      errorContent = feedback?.failure;
    } else {
      errorContent = 'Ops, request failed';
    }

    if (isClient && feedback?.failure) {
      message.error({
        content: errorContent,
        key: requestKey,
      });
    }
    throw error;
  } finally {
    setTimeout(() => {
      dispatch(endLoading({ requestKey, isInterruptive }));
    }, 600);
  }
};

const interceptedAxios = (history: History | false) => ({
  ...axios,
  get: <T = any>(
    url: string,
    config: ExtendedAxiosRequestConfig
  ): Promise<T> => {
    return interceptor(axios.get, url, history, config);
  },
  delete: <T = any>(
    url: string,
    config: ExtendedAxiosRequestConfig
  ): Promise<T> => {
    return interceptor(axios.delete, url, history, config);
  },
  post: <T = any>(
    url: string,
    body: any,
    config: ExtendedAxiosRequestConfig
  ): Promise<T> => {
    return interceptor(axios.post, url, history, config, body);
  },
  put: <T = any>(
    url: string,
    body: any,
    config: ExtendedAxiosRequestConfig
  ): Promise<T> => {
    return interceptor(axios.put, url, history, config, body);
  },
  patch: <T = any>(
    url: string,
    body: any,
    config: ExtendedAxiosRequestConfig
  ): Promise<T> => {
    return interceptor(axios.patch, url, history, config, body);
  },
});

export default useAxios;
