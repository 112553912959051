import React from 'react';
import classNames from 'classnames';

import './FlexChildUtil.scss';

export type FlexChildUtilProps = React.HTMLAttributes<
  Record<string, unknown>
> & {
  noGrow?: boolean;
  width?: number;
  alignSelf?:
    | 'auto'
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
};

export const FlexChildUtil: React.FC<FlexChildUtilProps> = (props) => {
  const { children, noGrow, width, alignSelf, className, style, ...attr } =
    props;
  const element = children as React.ReactElement;

  const flexStyle = alignSelf ? { alignSelf } : {};

  const fixedWidth = width
    ? { width: `${width}px`, minWidth: `${width}px`, maxWidth: `${width}px` }
    : {};

  const renderClassName = (): string => {
    return classNames(element.props.className, className, 'af-flex-child', {
      '--no-grow': noGrow,
    });
  };

  const renderStyle = (): React.StyleHTMLAttributes<
    Record<string, unknown>
  > => ({
    ...element.props.style,
    ...style,
    ...flexStyle,
    ...fixedWidth,
  });

  return React.cloneElement(element, {
    className: renderClassName(),
    style: renderStyle(),
    ...attr,
  });
};
