import { useSelector } from 'react-redux';
import { activityActionType } from '../helper/constants';
import { RootState } from '../store/store';
import { Activity } from '../helper/type';

export const useApplicantStatus = (type: 'hirer' | 'talent') => {
  const { mappedActivities: hirerActivities } = useSelector(
    (state: RootState) => state.hirer
  );
  const { mappedActivities: talentActivities } = useSelector(
    (state: RootState) => state.talent
  );

  const getLatestActivity = (
    roleId: string,
    talentId: string,
    options?: { includeActionType?: string[]; excludeActionType?: string[] }
  ) => {
    let activities: Activity[] | undefined;
    if (type === 'hirer' && hirerActivities[roleId]) {
      activities = hirerActivities[roleId][talentId];
    } else {
      activities = talentActivities[roleId];
    }

    if (!activities || activities.length === 0) return;

    let includeActionType = options?.includeActionType;
    const { excludeActionType = [] } = options ?? {};

    if (!includeActionType) {
      const excludeTypes = [
        'viewedProfile',
        'viewedComcard',
        'viewedCastingVideo',
        ...excludeActionType,
      ];
      includeActionType = Object.keys(activityActionType)
        .filter((type) => !excludeTypes.includes(type))
        .map(
          (key) => activityActionType[key as keyof typeof activityActionType]
        );
    }

    const activity = [...activities]
      .filter((act) => includeActionType?.includes(act.actionType))
      .sort((a, b) =>
        (b.lastUpdateAt ?? b.createdAt).localeCompare(
          a.lastUpdateAt ?? a.createdAt
        )
      )[0];

    return activity;
  };

  const getApplicantStatus = ({
    roleId = '',
    talentId = '',
    activity,
  }: {
    roleId?: string;
    talentId?: string;
    activity?: Activity;
  }) => {
    activity = activity ?? getLatestActivity(roleId, talentId);
    if (!activity) return;

    const isCasting = activity.actionType === activityActionType.casting;
    if (isCasting && activity.replyStatus === 'SCHEDULED')
      return 'castingScheduled';
    if (isCasting && activity.replyStatus === 'PENDING')
      return 'castingInvited';

    const statusTextKey = {
      [activityActionType.hirerShortlistApplicant]: 'shortlisted',
      [activityActionType.hirerConfirmApplication]: 'pendingConfirmation',
      [activityActionType.talentAcceptConfirmation]: 'confirmationAccepted',
      [activityActionType.talentRejectConfirmation]: 'confirmationRejected',
      [activityActionType.talentWithdrawApplication]: 'withdrawn',
    };

    return statusTextKey[activity.actionType] || 'none';
  };

  return { getLatestActivity, getApplicantStatus };
};
