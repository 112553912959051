import { activityActionType } from '../constants';
import { Activity } from '../type';

type MappedActivities = Record<string, Record<string, Activity[]>>;

export const getCastingInvitation = (
  roleId: string,
  talentId: string,
  mappedActivities: MappedActivities
): Activity | undefined => {
  if (
    !(roleId in mappedActivities) ||
    !mappedActivities[roleId][talentId]?.length
  )
    return;

  const activities = mappedActivities[roleId][talentId];
  const inv = activities
    .filter((act: Activity) => act.actionType === activityActionType.casting)
    .sort((a, b) =>
      (b.lastUpdateAt ?? b.createdAt).localeCompare(
        a.lastUpdateAt ?? a.createdAt
      )
    )[0];

  return inv;
};

export const getApplicantConfirmed = (
  roleId: string,
  talentId: string,
  mappedActivities: MappedActivities
) => {
  if (
    !(roleId in mappedActivities) ||
    !mappedActivities[roleId][talentId]?.length
  )
    return;

  const confirmActions = [
    activityActionType.hirerConfirmApplication,
    activityActionType.talentAcceptConfirmation,
    activityActionType.talentRejectConfirmation,
  ];
  const activities = mappedActivities[roleId][talentId];
  const confirmed = activities.find((act) =>
    confirmActions.includes(act.actionType)
  );

  return confirmed;
};

export const getApplicantWithdrawn = (
  roleId: string,
  talentId: string,
  mappedActivities: MappedActivities
) => {
  if (
    !(roleId in mappedActivities) ||
    !mappedActivities[roleId][talentId]?.length
  )
    return;

  const activities = mappedActivities[roleId][talentId];
  const wthdrw = activities.find(
    (act) => act.actionType === activityActionType.talentWithdrawApplication
  );

  return wthdrw;
};
