import React, { FC } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import { Div } from '../../framework';
import { RootState } from '../../store/store';

import './DirectoryCard.scss';

const { Text, Paragraph } = Typography;

const skeletonTitleProps = {
  baseColor: '#ebebeb33',
  highlightColor: '#f5f5f533',
};

const SkeletonDirectoryCard: FC = () => {
  const { loadStack } = useSelector((state: RootState) => state.app);

  return loadStack.includes('job/project/list/active') ? (
    <Div className='skeleton-directory-card'>
      <Div>
        <Text className='project-title'>
          <Skeleton width={130} height={20} {...skeletonTitleProps} />
        </Text>

        <Div ph='m' pv='xs' className='project-details'>
          <Div className='project-info' pb='xs'>
            <EnvironmentOutlined className='icon' />
            <Skeleton width={70} height={10} />
          </Div>
          <Div className='project-info' pb='xs'>
            <CalendarOutlined className='icon' />
            <Skeleton width={90} height={10} />
          </Div>
          <Paragraph
            className='project-description'
            style={{ marginBottom: '0.25rem' }}
          >
            <Skeleton width={300} height={10} />
          </Paragraph>
          <Div className='project-description '>
            <Skeleton width={180} height={10} />
          </Div>
        </Div>
      </Div>
      <Div className='project-roles'></Div>
    </Div>
  ) : null;
};

export default SkeletonDirectoryCard;
