import { Role, RoleType, Talent } from '../type';

const RoleImportance: Record<RoleType, number> = {
  main: 3,
  featured: 2,
  'featured extra': 1,
  background: 0,
};

const sortRolesForHirer = (roles: Role[]): Role[] => {
  return roles.sort((a, b) => {
    const roleAImportance = RoleImportance[a.type.toLowerCase() as RoleType];
    const roleBImportance = RoleImportance[b.type.toLowerCase() as RoleType];

    // no op if role.type is not defined
    if (roleAImportance === undefined || roleBImportance === undefined) {
      return 0;
    }

    // if a is more important than b, a should be ordered before b
    if (roleAImportance > roleBImportance) {
      return -1;
    }

    // otherwise, a should be ordered after b
    if (roleBImportance < roleAImportance) {
      return 1;
    }

    return 0;
  });
};

export default sortRolesForHirer;
