import { useState } from 'react';
import { isServer } from '../helper/detect';

type AsyncData<T> = {
  data: T;
  error: Error;
  done: boolean;
};

let done;
let setDone: React.Dispatch<boolean>;

export function useAsyncData<T = any>(
  component: React.FC & {
    asyncData: (args: Record<string, string>) => Promise<T>;
  },
  props: any,
  reqArgs: Record<string, string>
): AsyncData<T> {
  if (isServer) {
    const { data, error, done } = props.staticContext;
    return { data, error, done };
  }

  const [data, setData] = useState(window.ASYNC_DATA?.data || undefined);
  const [error, setError] = useState(window.ASYNC_DATA?.error || undefined);
  [done, setDone] = useState(!!window.ASYNC_DATA?.done || false);

  if (!done) {
    component
      .asyncData(reqArgs)
      .then((val: T) => {
        setData(val);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setDone(true);
      });
  }

  if (done && window.ASYNC_DATA?.done) {
    delete window.ASYNC_DATA.done;
  }

  return { data, error, done };
}
