import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { Div, Button } from '../../framework';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';
import { useHistory } from 'react-router-dom';
import { useTalentService } from '../../service/talent.service';
import { setPartialTalentProfile } from '../../store/user.slice';

const { Title, Paragraph } = Typography;

const PromptConfirmDuitNowModal: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const talentService = useTalentService();
  const { talent } = useSelector((state: RootState) => state.user);

  const { name = '', phoneNumber = '', phoneNumberPrefix = '' } = talent;

  const updatePaymentMethod = async () => {
    if (!talent.id) return;

    await talentService.updateProfile(
      {
        paymentMethod: {
          beneficieryName: name,
          channelCode: 'MY_DUITNOW',
        },
      },
      talent.id
    );

    dispatch(
      setPartialTalentProfile({
        paymentMethod: {
          beneficieryName: name,
          channelCode: 'MY_DUITNOW',
        },
        phoneNumber,
      })
    );

    dispatch(closeModal());
  };

  return (
    <Div className='talent-confirm-duitnow-modal'>
      <Div
        style={{
          width: '80px',
          height: '61px',
          overflow: 'hidden',
          margin: '0 auto 1rem',
        }}
      >
        <img
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/duitnow.png'
          style={{ width: '100%', objectFit: 'cover' }}
        />
      </Div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Confirm your DuitNow ID
      </Title>
      <Div mb='l' style={{ textAlign: 'center' }}>
        <Paragraph style={{ color: 'black' }}>
          To ensure a smooth process when receiving your payment, please confirm
          if this mobile number is your DuitNow ID.
        </Paragraph>
      </Div>
      <Div mv='xxl' style={{ textAlign: 'center' }}>
        <span style={{ fontWeight: 600, fontSize: 'large' }}>{name}</span>
        <br />
        <span style={{ fontWeight: 600, fontSize: 'large' }}>
          {`${phoneNumberPrefix}${phoneNumber}`.replace(
            /(\d{1})(\d{3})(\d{3,4})(\d{4})/,
            '$1 $2 $3 $4'
          )}
        </span>
      </Div>
      <Div mb='m'>
        <Button
          block
          solid
          style={{ marginBottom: '0.75rem' }}
          onClick={updatePaymentMethod}
        >
          Yes, this is correct
        </Button>
        <Button
          block
          ghost
          onClick={() => {
            history.push('/talent/profile/contact?edit=true');
            dispatch(closeModal());
          }}
        >
          No, edit mobile number
        </Button>
      </Div>
    </Div>
  );
};

export default PromptConfirmDuitNowModal;
