import { companyPhoneNumber } from '../../config/app.config';

export const createWaLink = (message: string) => {
  return encodeURI(
    `https://api.whatsapp.com/send?phone=+601154110075&text=${message}`
  );
};

export const openWaChat = () =>
  encodeURI(`https://wa.me/${companyPhoneNumber.replace('+', '')}`);
