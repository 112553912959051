import React, { FunctionComponent } from 'react';
import './Page.scss';

type PageProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  align?: 'left' | 'right' | 'center';
  className?: string;
};

const alignment = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const Page: FunctionComponent<PageProps> = ({
  align = 'left',
  className,
  children,
}) => {
  return (
    <div
      style={{ alignItems: alignment[align] }}
      className={`component-page ${className}`.trim()}
    >
      {children}
    </div>
  );
};

export { Page };
