import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { DatePicker, FormInstance, Input } from 'antd';
import moment from 'moment';
import { format } from 'date-fns';

import './MultipleDatePicker.scss';

type Props = {
  name: string;
  placeholder?: string;
  form: FormInstance;
  dates?: string[];
  disabledDate?: (date: any) => boolean;
};

const MultipleDatePicker: FC<Props> = ({
  name,
  placeholder,
  form,
  dates,
  disabledDate,
}) => {
  // selected date in timestamp
  const [selectedDate, setSelectedDate] = useState<number[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (dates && dates.length > 0) {
      setSelectedDate(
        dates.map((d) => moment(d).startOf('day').valueOf()).sort()
      );
    }
  }, [dates]);

  useEffect(() => {
    form.setFieldValue(name, [...(selectedDate.map((d) => moment(d)) || [])]);
  }, [selectedDate]);
  // on change reset the state
  const onValueChange = (date: any) => {
    const newDate = moment(date).startOf('day').valueOf();
    selectedDate.includes(newDate)
      ? setSelectedDate([...selectedDate.filter((item) => item !== newDate)])
      : setSelectedDate([...selectedDate, newDate]);
  };

  // render selected Date
  const renderDate = (currentDate: any) => {
    const isSelected = selectedDate.includes(
      moment(currentDate).startOf('day').valueOf()
    );
    const selectStyle: CSSProperties = isSelected
      ? {
          position: 'relative',
          zIndex: 2,
          display: 'inlineBlock',
          width: '24px',
          height: '22px',
          lineHeight: '22px',
          backgroundColor: '#1890ff',
          color: '#fff',
          margin: 'auto',
          borderRadius: '2px',
          transition: 'background 0.3s, border 0.3s',
        }
      : {};
    return <div style={selectStyle}> {currentDate.date()} </div>;
  };

  return (
    <div className='multiple-datepicker'>
      <Input
        readOnly
        className='date-input'
        placeholder={placeholder}
        value={selectedDate
          .map((d) => format(new Date(d), 'dd MMM yyyy'))
          .join(', ')}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
      />
      <DatePicker
        open={open}
        dateRender={renderDate}
        onSelect={onValueChange}
        showToday={false}
        value={null}
        disabledDate={disabledDate}
        className='calendar-picker'
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
};

export default MultipleDatePicker;
