import React, { FC, useState } from 'react';
import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ReplySvg from '../../framework/Icon/ReplySvg';
import { Div } from '../../framework';

import './ReplyCommentButton.scss';

type Props = {
  onSubmit?: (reply: string) => void;
};

const ReplyCommentButton: FC<Props> = ({ onSubmit }) => {
  const [showTextbox, setShowTextbox] = useState(false);
  const [replyText, setReplyText] = useState('');

  const handleSubmit = () => {
    onSubmit && onSubmit(replyText);
    setReplyText('');
    setShowTextbox(false);
  };

  return (
    <Div className='div-reply-comment-button'>
      {!showTextbox && (
        <Button
          type='text'
          className='btn-reply-comment'
          onClick={() => setShowTextbox(true)}
        >
          <ReplySvg /> <span>Reply</span>
        </Button>
      )}
      {showTextbox && (
        <Div>
          <Div className='reply-textbox-title'>Replying to comment</Div>
          <Div className='reply-textbox-desc'>
            Remember to keep your comments positive and supportive!
          </Div>
          <TextArea
            rows={5}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <Button
            type='primary'
            block
            className='btn-submit'
            disabled={replyText.length === 0}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Div>
      )}
    </Div>
  );
};

export default ReplyCommentButton;
