import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Empty } from 'antd';
import { Div } from '../../framework';
import { Comment, Rating, Reply } from '../../helper/type';
import RatingComment from '../RatingComment/RatingComment';
import ReplyCommentButton from '../RatingComment/ReplyCommentButton';
import { openModal } from '../../store/app.slice';
import { RootState } from '../../store/store';
import { useRatingService } from '../../service/rating.service';

import './RatingCommentList.scss';

type Props = {
  userType: 'talent' | 'hirer';
  currentRating?: Rating;
  fetchRating: () => Promise<void>;
};

const RatingCommentList: FC<Props> = ({
  userType,
  currentRating,
  fetchRating,
}) => {
  const dispatch = useDispatch();
  const ratingService = useRatingService();
  const { search } = useLocation();
  const { modal } = useSelector((state: RootState) => state.app);
  const { authUser, authHirer, profilePictureUrl, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { companyProfile } = useSelector((state: RootState) => state.hirer);

  const [openedModal, setOpenedModal] = useState<string>();

  const isTalentImp = impersonator.impersonateId && impersonator.impersonateKey;
  const highlightedComment = new URLSearchParams(search).get('id') as string;

  useEffect(() => {
    fetchRating();
  }, []);

  useEffect(() => {
    if (modal.name) setOpenedModal(modal.name);
    else if (openedModal === 'delete-comment') {
      fetchRating();
    }
  }, [modal.name]);

  const updateComment = async (commentId: string, comment: string) => {
    if (!currentRating) return;

    await ratingService.updateComment(
      currentRating.ratingId,
      commentId,
      comment
    );
    await fetchRating();
  };
  const submitReply = async (commentId: string, reply: string) => {
    if (!currentRating) return;

    let replyPayload: Partial<Reply> & { impkey?: string } = {
      talentId: currentRating.talentId,
      commentId,
      ratingId: currentRating.ratingId,
      roleId: currentRating.roleId,
      reply,
    };
    if (userType === 'talent') {
      replyPayload = {
        ...replyPayload,
        authorType: 'talent',
        authorName: authUser.name,
        authorProfilePicThumb: profilePictureUrl,
      };
    } else {
      replyPayload = {
        ...replyPayload,
        authorType: 'hirer',
        authorName: companyProfile.companyName,
        authorProfilePicThumb: companyProfile.profilePicThumbnail,
        hirerId: authHirer.hirerId,
        userId: authHirer.userId,
      };
    }

    await ratingService.createReply(replyPayload);
    await fetchRating();
  };
  const updateReply = async (replyId: string, reply: string) => {
    if (!currentRating) return;

    await ratingService.updateReply(currentRating.ratingId, replyId, reply);
    await fetchRating();
  };
  const promptDeleteComment = (type: 'comment' | 'reply', id: string) => {
    if (!currentRating) return;

    dispatch(
      openModal({
        modal: 'delete-comment',
        config: {
          context: {
            type,
            ratingId: currentRating.ratingId,
            ...(type === 'comment' ? { commentId: id } : { replyId: id }),
          },
        },
      })
    );
  };

  const isCommentEditable = (comment: Comment) => {
    if (userType === 'talent' && comment.authorType === 'talent') {
      return isTalentImp
        ? comment.talentId === impersonator.impersonateId
        : comment.talentId === authUser.username;
    }

    return false;
  };
  const isReplyEditable = (reply: Reply) => {
    if (userType === 'talent' && reply.authorType === 'talent') {
      return isTalentImp
        ? reply.talentId === impersonator.impersonateId
        : reply.talentId === authUser.username;
    }

    return reply.authorType === 'hirer' && reply.hirerId === authHirer.hirerId;
  };

  if (!currentRating) {
    return (
      <Div className='zero-state'>
        <Empty description='Rating not found' />
      </Div>
    );
  }

  return (
    <Div className='rating-comment-list'>
      {currentRating?.comments.map((comment) => (
        <Div key={comment.commentId} className='comment-container'>
          <Div mb='xl'>
            <RatingComment
              authorName={comment.authorName}
              comment={comment.comment}
              commentDate={comment.createdAt}
              profilePic={comment.authorProfilePicThumb}
              editable={isCommentEditable(comment)}
              onEdit={(newValue) =>
                updateComment && updateComment(comment.commentId, newValue)
              }
              onDelete={() => promptDeleteComment('comment', comment.commentId)}
              highlight={highlightedComment === comment.commentId}
            />
          </Div>
          <Div className='comment-replies' pl='xl'>
            {comment.replies.map((reply) => (
              <Div key={reply.replyId} mb='xl'>
                <RatingComment
                  authorName={reply.authorName}
                  comment={reply.reply}
                  commentDate={reply.createdAt}
                  profilePic={reply.authorProfilePicThumb}
                  editable={isReplyEditable(reply)}
                  onEdit={(newValue) =>
                    updateReply && updateReply(reply.replyId, newValue)
                  }
                  onDelete={() => promptDeleteComment('reply', reply.replyId)}
                  highlight={highlightedComment === reply.replyId}
                />
              </Div>
            ))}
          </Div>
          <Div>
            <ReplyCommentButton
              onSubmit={(reply) =>
                submitReply && submitReply(comment.commentId, reply)
              }
            />
          </Div>
        </Div>
      ))}

      {currentRating?.comments.length === 0 && (
        <Div className='zero-state'>
          <Empty description='No one comment on this rating yet' />
        </Div>
      )}
    </Div>
  );
};

export default RatingCommentList;
