import React from 'react';

const NextSvg = () => (
  <svg
    width='14'
    height='24'
    viewBox='0 0 14 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.43302 11.8044L0.496021 2.8744C0.338919 2.71818 0.214247 2.53245 0.129174 2.32788C0.0441011 2.12331 0.000305176 1.90395 0.000305176 1.6824C0.000305176 1.46084 0.0441011 1.24148 0.129174 1.03691C0.214247 0.832345 0.338919 0.646613 0.496021 0.490396C0.814283 0.175591 1.24387 -0.000976562 1.69152 -0.000976562C2.13917 -0.000976562 2.56876 0.175591 2.88702 0.490396L13.012 10.6044C13.3179 10.9115 13.4937 11.3247 13.5029 11.7581C13.512 12.1915 13.3537 12.6117 13.061 12.9314L2.89402 23.1244C2.73749 23.2814 2.55156 23.406 2.34686 23.4912C2.14216 23.5763 1.9227 23.6203 1.701 23.6206C1.4793 23.6209 1.2597 23.5776 1.05476 23.4931C0.849807 23.4085 0.663517 23.2844 0.506521 23.1279C0.349526 22.9714 0.2249 22.7854 0.139759 22.5807C0.0546179 22.376 0.0106295 22.1566 0.0103045 21.9349C0.00964807 21.4871 0.186883 21.0575 0.503021 20.7404L9.43302 11.8044Z'
      fill='black'
    />
  </svg>
);

export default NextSvg;
