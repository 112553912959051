export const isServer = (() => {
  return typeof window === 'undefined';
})();

export const isClient = (() => {
  return typeof window !== 'undefined';
})();

export const isMobile = (() => {
  // as per MDN's recommended approach
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
  return isClient && /Mobi/.test(window.navigator.userAgent);
})();
