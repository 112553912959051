import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  ForwardRefRenderFunction,
  FC,
} from 'react';
import { PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Spin, Typography, Button } from 'antd';
import imageCompression, {
  Options as CompressionOption,
} from 'browser-image-compression';
import { useDropzone } from 'react-dropzone';
import ImageCropper from './ImageCropper';
import React from 'react';
import {
  UploadFilePayload,
  useFileManageService,
} from '../../service/filemanage.service';
import { nanoid } from 'nanoid';

const { Text } = Typography;

export type Photo = {
  isNew?: boolean;
  isMarkedForDelete?: boolean;
  original: string | File;
  thumbnail: string | File;
};

type Props = {
  value?: Photo[];
  buttonLabel?: string;
  label?: string;
  disabled?: boolean;
  shouldRemoveMarkedForDelete?: boolean;
  fileSizeThresholdToCompressInKb: number;
  maxFileSizeInKb: number;
  required?: boolean;
  errorMessage?: string;
  scope: string;
  directory: string;
  multiple?: boolean;
  cropAspect?: number;
  onChange?: (value: Photo[]) => void;
  onStatusChange?: (isChange: boolean) => void;
  renderPreview?: (props: {
    originalUrl: string;
    thumbnailUrl: string;
    handleDeletePhoto: () => void;
  }) => React.ReactNode;
  renderDropzone?: () => React.ReactNode;
};

export type PhotoManagerHandle = {
  update: () => Promise<Photo[]>;
  validate: () => boolean;
  reset: () => void;
  openFileDialog: () => void;
  hasChanged: () => boolean;
};

type PhotoFilter = Pick<Photo, 'isNew' | 'isMarkedForDelete'>;
const byNew = (i: PhotoFilter) => i.isNew && !i.isMarkedForDelete;
const byMarkedForDelete = (i: PhotoFilter) => !i.isNew && i.isMarkedForDelete;
const byNoChange = (i: PhotoFilter) => !i.isNew && !i.isMarkedForDelete;

const getPhotoUrl = (photo: File | string) =>
  typeof photo === 'string' ? photo : URL.createObjectURL(photo);

const PhotoManager: ForwardRefRenderFunction<PhotoManagerHandle, Props> = (
  {
    value,
    buttonLabel,
    onChange,
    onStatusChange,
    renderPreview,
    renderDropzone: propRenderDropzone,
    disabled,
    multiple,
    fileSizeThresholdToCompressInKb,
    maxFileSizeInKb,
    shouldRemoveMarkedForDelete,
    label,
    cropAspect,
    required,
    errorMessage,
    scope,
    directory,
  }: Props,
  ref
) => {
  const fileManageService = useFileManageService();

  const [photos, setPhotos] = useState<Photo[]>([]);
  const [compressingCount, setCompressingCount] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<string>();
  const [fileToCrop, setFileToCrop] = useState<File>();
  const [showCropper, setShowCropper] = useState(false);
  const isCropperEnabled = !!cropAspect;
  const isMultiple = !isCropperEnabled && multiple;

  if (isCropperEnabled && multiple) {
    console.warn(
      'PhotoManager has detected both "cropAspect" and "multiple" props enabled. "multiple" is automatically disable when image cropper is enabled. To get rid of the warning, set "multiple" to "false".'
    );
  }

  useEffect(() => {
    setPhotos(value ?? []);
  }, [value]);

  useEffect(() => {
    onStatusChange && onStatusChange(compressingCount > 0);
  }, [onStatusChange, compressingCount]);

  const handleUpdate = useCallback(async () => {
    const itemsToUpload: UploadFilePayload[] = [];

    photos.filter(byNew).forEach((photo) => {
      const fileName = nanoid();
      itemsToUpload.push(
        {
          scope,
          directory,
          file: photo.original as File,
          fileName,
        },
        {
          scope,
          directory,
          file: photo.thumbnail as File,
          fileName: `${fileName}.thumbnail`,
        }
      );
    });
    const newPhotoUrls = await Promise.all(
      itemsToUpload.map((item) => fileManageService.uploadFile(item))
    );

    if (shouldRemoveMarkedForDelete) {
      const keysToDelete: string[] = [];
      photos
        .filter(byMarkedForDelete)
        .forEach((photo) =>
          keysToDelete.push(photo.original as string, photo.thumbnail as string)
        );

      if (keysToDelete.length > 0)
        await fileManageService.deleteFile({ keys: keysToDelete as string[] });
    }

    const newPhotos: Record<string, Photo> = {};
    newPhotoUrls.forEach(({ url, fileName = '' }) => {
      const id = fileName.replace('.thumbnail', '');
      fileName?.includes('.thumbnail')
        ? (newPhotos[id] = { ...newPhotos[id], thumbnail: url })
        : (newPhotos[id] = { ...newPhotos[id], original: url });
    });

    const newValue = [
      ...Object.values(newPhotos),
      ...photos.filter(byNoChange),
    ];
    setPhotos(newValue);

    return newValue;
  }, [value, photos, shouldRemoveMarkedForDelete]);

  const handleValidate = useCallback(() => {
    if (required && photos.filter((p) => !byMarkedForDelete(p)).length > 0) {
      setHasError(true);
      return false;
    }

    return true;
  }, [required, value]);

  const addNewFiles = async (files: File[]) => {
    if (files?.length) {
      const newItems: Photo[] = [];
      setCompressingCount(files.length);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const originalOptions: CompressionOption = {
          maxSizeMB: maxFileSizeInKb / 1024,
          useWebWorker: true,
          initialQuality: 0.7,
          fileType: 'image/jpeg',
        };
        const thumbnailOptions: CompressionOption = {
          maxWidthOrHeight: 340,
          useWebWorker: true,
          initialQuality: 0.8,
          fileType: 'image/jpeg',
        };

        try {
          const original =
            file.size / 1024 > fileSizeThresholdToCompressInKb
              ? await imageCompression(file, originalOptions)
              : file;

          const thumbnail = await imageCompression(file, thumbnailOptions);

          newItems.push({ original, thumbnail, isNew: true });
        } catch (error) {
          console.warn(error);
        }
      }

      const newValue = [
        ...newItems,
        ...(isMultiple
          ? photos
          : photos
              .filter((photo) => !photo.isNew)
              .map((photo) => ({
                ...photo,
                isMarkedForDelete: true,
              }))),
      ];

      setPhotos(newValue);

      onChange && onChange(newValue);

      setCompressingCount(0);
      setHasError(false);
    }
  };
  const handleSelectFiles = async (files: File[]) => {
    if (isCropperEnabled && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          setImageToCrop(reader.result);
          setShowCropper(true);
        }
      });
      reader.readAsDataURL(file);
      setFileToCrop(file);
    } else {
      await addNewFiles(files);
    }
  };
  const handleDeletePhoto = (index: number) => {
    const newValue = [...photos.slice(0, index), ...photos.slice(index + 1)];
    if (!photos[index].isNew)
      newValue.push({ ...photos[index], isMarkedForDelete: true });

    setPhotos(newValue);

    onChange && onChange(newValue);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
    isDragAccept,
    isDragReject,
    inputRef: dropzoneInputRef,
  } = useDropzone({
    disabled,
    multiple: isMultiple,
    accept: { 'image/*': [] },
    onDrop: handleSelectFiles,
  });
  const renderDropzone = () => {
    if (!isMultiple && photos.filter((p) => !byMarkedForDelete(p)).length > 0)
      return null;
    if (!isMultiple && compressingCount > 0) return <CompressingSpinner />;

    return (
      <div {...getRootProps({ style: { width: 'fit-content' } })}>
        <label
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input {...getInputProps()} accept='image/*' />
          {propRenderDropzone?.call(null) ?? (
            <DefaultDropzoneInput
              buttonLabel={buttonLabel}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            />
          )}
        </label>
      </div>
    );
  };

  useImperativeHandle(
    ref,
    () => ({
      update: handleUpdate,
      validate: handleValidate,
      reset: () => setPhotos(value ?? []),
      openFileDialog,
      hasChanged: () => photos.filter((p) => !byNoChange(p)).length > 0,
    }),
    [handleUpdate, handleValidate, openFileDialog]
  );

  return (
    <>
      {label ? (
        <Typography.Paragraph style={{ fontSize: '1rem' }}>
          {label}
        </Typography.Paragraph>
      ) : null}
      <div className='ant-upload-list-picture-card'>
        {isMultiple && compressingCount > 0
          ? new Array(compressingCount)
              .fill(1)
              ?.map((_, index) => <CompressingSpinner key={`spin-${index}`} />)
          : null}
        {photos
          .filter((item) => !byMarkedForDelete(item))
          .map((item, index) => (
            <div key={`preview-${index}`}>
              {renderPreview?.call(null, {
                originalUrl: getPhotoUrl(item.original),
                thumbnailUrl: getPhotoUrl(item.thumbnail),
                handleDeletePhoto: () => handleDeletePhoto(index),
              }) ?? (
                <DefaultPhotoPreview
                  original={getPhotoUrl(item.original)}
                  thumbnail={getPhotoUrl(item.thumbnail)}
                  handleDeletePhoto={() => handleDeletePhoto(index)}
                />
              )}
            </div>
          ))}
        {renderDropzone()}
        <ImageCropper
          image={imageToCrop}
          file={showCropper ? fileToCrop : undefined}
          aspect={cropAspect}
          onCropOk={(croppedFile: File) => {
            addNewFiles([croppedFile]);
            setShowCropper(false);
          }}
          onCropCancel={() => {
            setShowCropper(false);
            if (dropzoneInputRef.current) dropzoneInputRef.current.value = '';
          }}
        />
      </div>
      {hasError ? <Text type='danger'>{errorMessage}</Text> : null}
    </>
  );
};

const CompressingSpinner = () => (
  <div className='ant-upload-list-picture-card-container'>
    <div className='ant-upload-list-item ant-upload-list-item-list-type-picture-card'>
      <div className='ant-upload-list-item-info'>
        <span className='ant-upload-list-item-thumbnail'>
          <Spin />
        </span>
      </div>
    </div>
  </div>
);
// Note: this default preview is not mobile friendly
const DefaultPhotoPreview: FC<{
  original: string;
  thumbnail: string;
  handleDeletePhoto: () => void;
}> = ({ original, thumbnail, handleDeletePhoto }) => (
  <div className='ant-upload-list-picture-card-container'>
    <div className='ant-upload-list-item ant-upload-list-item-list-type-picture-card'>
      <div className='ant-upload-list-item-info'>
        <span className='ant-upload-list-item-span'>
          <div className='ant-upload-list-item-thumbnail'>
            <img src={thumbnail} className='ant-upload-list-item-image' />
          </div>
        </span>
      </div>
      <span
        className='ant-upload-list-item-actions'
        style={{ display: 'flex' }}
      >
        <a href={original} target='_blank' rel='noreferrer'>
          <EyeOutlined />
        </a>
        <Button
          type='text'
          size='small'
          className='ant-upload-list-item-card-actions-btn'
          onClick={handleDeletePhoto}
          icon={<DeleteOutlined />}
        />
      </span>
    </div>
  </div>
);
const DefaultDropzoneInput: FC<{
  buttonLabel?: string;
  isDragAccept?: boolean;
  isDragReject?: boolean;
}> = ({ buttonLabel, isDragAccept, isDragReject }) => (
  <>
    <div className='ant-upload ant-upload-select ant-upload-select-picture-card'>
      <div className='ant-upload'>
        <div>
          <PlusOutlined />
          <Text style={{ marginTop: '8px', display: 'block' }}>
            {buttonLabel}
          </Text>
        </div>
      </div>
    </div>
    {isDragAccept ? <div>Dropping files...</div> : null}
    {isDragReject ? <div>Invalid files</div> : null}
  </>
);

export default forwardRef(PhotoManager);
