import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CameraTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { videoThumbnailPlaceholder } from '../../../config/app.config';
import { Div } from '../../framework';
import { openModal } from '../../store/app.slice';

import './VideoUpload.scss';

type Props = {
  onChange?: (file?: File) => void;
  validExt?: string[];
};

const VideoUpload: FC<Props> = ({ onChange, validExt = ['mp4'], ...props }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [media, setMedia] = useState<File>();

  const handleAddMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxVideoFileSize = 25; // MB

    if (!event.target.files || event.target.files.length === 0) {
      setMedia(undefined);
      return;
    }

    const video = event.target.files[0];
    if (video.size / 1024 / 1024 > maxVideoFileSize) {
      setError('File size exceed limit.');
      setMedia(undefined);
      return;
    }

    const ext = video.name.split('.').slice(1).pop() ?? '';
    if (!validExt.includes(ext)) {
      setError(`Invalid file type, valid types: (${validExt.join(', ')})`);
      setMedia(undefined);
      return;
    }

    setError('');
    setMedia(video);

    onChange?.call(null, video);
  };

  const handleRemoveMedia = () => {
    setMedia(undefined);
    onChange?.call(null);
  };

  const openGallery = (index = 0) => {
    if (!media) return;

    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: [
              {
                original: URL.createObjectURL(media),
                thumbnail: videoThumbnailPlaceholder,
                type: 'video',
              },
            ],
            index,
          },
        },
      })
    );
  };

  return (
    <Div className='media-upload' mb='s' {...props}>
      {media ? (
        <>
          <img src={videoThumbnailPlaceholder} onClick={() => openGallery()} />
          <button className='close' onClick={handleRemoveMedia}>
            <CloseCircleOutlined />
          </button>
        </>
      ) : (
        <label className='upload-media-button'>
          <input
            type='file'
            accept='video/mp4'
            onChange={handleAddMedia}
            style={{ display: 'none' }}
          />
          <div>
            <CameraTwoTone />
          </div>
          Upload Video
        </label>
      )}

      <Div className='red'>{error}</Div>
    </Div>
  );
};

export default VideoUpload;
