import React, { FC } from 'react';
import { Progress, Typography } from 'antd';
import { Div } from '../Div/Div';

import './Steps.scss';
import FormSectionDropdown from '../../page/Talent/Form/FormSectionDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const { Title, Paragraph } = Typography;

type Step = {
  title: string;
};

type StepsProps = {
  current: number;
  steps: Step[];
};

const Steps: FC<StepsProps> = ({ current = 0, steps = [] }) => {
  const percent = ((current + 1) / steps.length) * 100;
  const { isTalentOnboarded } = useSelector((state: RootState) => state.user);

  return (
    <Div flex className='component-steps' noGrow>
      <Div className='progress-container' noGrow>
        <Progress
          type='circle'
          percent={percent}
          format={() => `${current + 1} of ${steps.length}`}
          width={66}
        />
      </Div>
      <Div className='text-container'>
        {isTalentOnboarded ? (
          <FormSectionDropdown currentStep={current}></FormSectionDropdown>
        ) : (
          <Title level={4}>{steps[current].title}</Title>
        )}
        <Paragraph type='secondary'>
          Next: {current !== steps.length - 1 ? steps[current + 1].title : '-'}
        </Paragraph>
      </Div>
    </Div>
  );
};

export { Steps };
