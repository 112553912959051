import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Div } from '../Div/Div';
import { BoxUtil, BoxUtilProps } from '../BoxUtil/BoxUtil';

import './Button.scss';

type Props =
  | React.AnchorHTMLAttributes<HTMLAnchorElement> &
      React.ButtonHTMLAttributes<HTMLButtonElement> &
      BoxUtilProps & {
        primary?: boolean;
        block?: boolean;
        rounded?: boolean;
        solid?: boolean;
        ghost?: boolean;
        to?: string;
      };

export const Button: FC<Props> = ({
  href,
  target,
  children,
  primary,
  block,
  rounded,
  solid,
  ghost,
  to,
  disabled,
  type,
  ...props
}) => {
  const className = `component-button ${primary ? '--primary' : ''} ${
    block ? '--block' : ''
  } ${rounded ? '--rounded' : ''} ${solid ? '--solid' : ''} ${
    ghost ? '--ghost' : ''
  }`.trim();

  return (
    <BoxUtil {...props}>
      <Div>
        {href ? (
          <a href={href} target={target} className={className}>
            {children}
          </a>
        ) : to ? (
          <Link to={to}>
            <button
              type={type ?? 'button'}
              className={className}
              disabled={disabled}
            >
              {children}
            </button>
          </Link>
        ) : (
          <button
            type={type ?? 'button'}
            className={className}
            disabled={disabled}
          >
            {children}
          </button>
        )}
      </Div>
    </BoxUtil>
  );
};
