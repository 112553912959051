import moment from 'moment';
import { Role, Talent } from '../type';

export enum RolePriority {
  RECOMMENDED = 2,
  SUITABLE = 1,
  NOT_SUITABLE = 0,
}

/**
 * Matches a role with a talent based on the following criterias:
 * - talent's age must be +- 7 of the role's age
 * - talent's gender must satisfy the role
 * - talent's race must satisfy the role
 * @param talent
 * @param role
 * @returns {number} The priority. 2 if matching all criteria, 1 if matching gender only, 0 for failing gender check
 */
const matchRoleForTalent = (talent: Talent, role: Role) => {
  const talentAge = moment().diff(
    talent.dob ?? new Date().toISOString(),
    'years'
  );
  const jobMinAgeConstraint = role.ageFrom - 7;
  const jobMaxAgeConstraint = role.ageTo + 7;
  const isAgeMatch =
    talentAge >= jobMinAgeConstraint && talentAge <= jobMaxAgeConstraint;

  // match the first letter of (f)emale , (m)ale or (o)ther
  const talentGender = talent.gender ?? '0';
  const isGenderMatch =
    role.gender.toLowerCase() === 'any'
      ? true
      : role.gender[0].toLowerCase() === talentGender[0].toLowerCase();

  const talentRace = talent.race?.toLowerCase().trim() ?? '';
  const roleRace = role.race?.toLowerCase().trim() ?? '';
  const roleEthnicity = role.ethnicity?.toLowerCase().trim() ?? '';
  const roleRaceEthnicity = role.raceEthnicity ?? [roleRace, roleEthnicity];
  const isAnyRace =
    roleRace === 'any' || role.raceEthnicity?.includes('any race');

  const isTalentSpecificRace = ['malay', 'chinese', 'indian'].includes(
    talentRace
  );

  const isRaceMatch =
    isAnyRace || !isTalentSpecificRace
      ? true
      : roleRaceEthnicity.includes(talentRace);

  const isEthnicityMatch = roleRaceEthnicity.includes('caucasian')
    ? !isTalentSpecificRace
    : true;

  const isRecommended =
    isAgeMatch && isGenderMatch && isRaceMatch && isEthnicityMatch;
  const isSuitable = isGenderMatch && isRaceMatch;
  const priority = isRecommended
    ? RolePriority.RECOMMENDED
    : isSuitable
    ? RolePriority.SUITABLE
    : RolePriority.NOT_SUITABLE;

  return priority;
};

export default matchRoleForTalent;
