import React, { FC } from 'react';
import { Div, Button } from '../';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';

import './StepperInput.scss';

type Props = {
  value: number;
  onChange?: (val: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
};
export const StepperInput: FC<Props> = ({
  value,
  onChange,
  min,
  max,
  disabled,
}) => {
  const handleValueChange = (val: number) => {
    if (min != undefined && val < min) val = min;
    if (max != undefined && val > max) val = max;

    onChange && onChange(val);
  };

  return (
    <Div flex className='component-stepper-input'>
      <Button
        ghost
        onClick={() => handleValueChange(value - 1)}
        className='btn-minus'
        disabled={disabled}
      >
        <MinusCircleOutlined />
      </Button>
      <InputNumber
        value={value}
        onChange={(val) => handleValueChange(val ?? 0)}
        className='input-number'
        min={min}
        max={max}
        disabled={disabled}
      />
      <Button
        ghost
        onClick={() => handleValueChange(value + 1)}
        className='btn-plus'
        disabled={disabled}
      >
        <PlusCircleOutlined />
      </Button>
    </Div>
  );
};
