import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { RootState } from '../../store/store';
import { Div } from '../../framework';
import { closeModal } from '../../store/app.slice';
import { useRatingService } from '../../service/rating.service';

export type ModalContext = {
  type: 'comment' | 'reply';
  ratingId: string;
  commentId?: string;
  replyId?: string;
};

const { Title, Paragraph } = Typography;

const DeleteCommentModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const ratingService = useRatingService();

  const { modal } = useSelector((state: RootState) => state.app);
  const { context = {} } = modal.config;
  const {
    type,
    ratingId,
    commentId = '',
    replyId = '',
  } = context as ModalContext;

  const handleDeleteComment = async () => {
    if (type === 'comment') {
      await ratingService.deleteComment(ratingId, commentId);
    } else {
      await ratingService.deleteReply(ratingId, replyId);
    }
    closeSelf();
  };

  const closeSelf = () => {
    dispatch(closeModal());
  };

  return (
    <Div className='delete-comment-modal'>
      <Div style={{ textAlign: 'center' }} pt='m'>
        <Title level={3}>
          <Div>
            <WarningTwoTone twoToneColor='red' />
          </Div>
          {type === 'comment'
            ? 'Delete comment and following replies?'
            : 'Delete comment?'}
        </Title>
      </Div>

      <Paragraph style={{ textAlign: 'center' }}>
        This action cannot be undone.
      </Paragraph>

      <Div ph='xl' mt='xxl'>
        <Button type='primary' size='large' block onClick={handleDeleteComment}>
          Yes, delete
        </Button>
        <Button
          type='link'
          size='large'
          block
          onClick={closeSelf}
          style={{ marginTop: '1rem' }}
        >
          Cancel
        </Button>
      </Div>
    </Div>
  );
};

export default DeleteCommentModal;
