import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { Div } from '../../framework';
import RatingComment from '../RatingComment/RatingComment';
import { RootState } from '../../store/store';
import { Rating } from '../../helper/type';

import './RatingCommentModal.scss';

export type ModalContext = {
  talentId: string;
  roleId: string;
};

const RatingCommentModal: FC = () => {
  const { mappedRatings } = useSelector((state: RootState) => state.talent);
  const { modal } = useSelector((state: RootState) => state.app);
  const { context = {} } = modal.config;
  const { talentId, roleId } = context as ModalContext;

  const [rating, setRating] = useState<Rating>();

  useEffect(() => {
    if (mappedRatings[talentId] && mappedRatings[talentId][roleId]) {
      setRating(mappedRatings[talentId][roleId]);
    }
  }, []);

  if (!rating) {
    return (
      <Div>
        <Div
          style={{
            fontWeight: 600,
            fontSize: 'large',
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        >
          Rating not found
        </Div>
        <Empty />
      </Div>
    );
  }

  return (
    <Div className='talent-rating-comment-modal'>
      <Div mb='xl'>
        <RatingComment
          authorName={rating.authorCompanyName}
          comment={rating.writtenReview ?? ''}
          commentDate={rating.createdAt}
          profilePic={rating.authorProfilePicThumb}
        />
      </Div>
      {rating.comments?.map((comment) => (
        <Div key={comment.commentId} className='comment-replies' pl='xl' mb='l'>
          <Div mb='xl'>
            <RatingComment
              authorName={comment.authorName}
              comment={comment.comment}
              commentDate={comment.createdAt}
              profilePic={comment.authorProfilePicThumb}
            />
          </Div>
          {comment.replies?.map((reply) => (
            <Div key={reply.replyId} mb='xl'>
              <RatingComment
                authorName={reply.authorName}
                comment={reply.reply}
                commentDate={reply.createdAt}
                profilePic={reply.authorProfilePicThumb}
              />
            </Div>
          ))}
        </Div>
      ))}
    </Div>
  );
};

export default RatingCommentModal;
