import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Div } from '../../framework';
import { RootState } from '../../store/store';

import './HirerViewRatingCard.scss';

const SkeletonHirerViewRatingCard: FC = () => {
  const { loadStack } = useSelector((state: RootState) => state.app);

  return loadStack.find((stack) => stack.startsWith('rating/project/')) ? (
    <Div className='skeleton-hirer-view-rating-card'>
      <Div flex className='header'>
        <Div className='title'>
          <Skeleton
            width={100}
            height={20}
            baseColor='#ebebeb33'
            highlightColor='#f5f5f533'
          />
        </Div>
      </Div>
      <Div flex className='talent-container'>
        <Div flex className='left-column'>
          <Div className='photo'></Div>
        </Div>
        <Div flex className='right-column'>
          <Div className='summary'>
            <Div className='role-title'>
              <Skeleton width={200} height={10} />
            </Div>
            <Div className='shoot-dates'>
              <Skeleton width={200} height={10} />
            </Div>
            <Div className='hire-again'>
              <Skeleton width={200} height={10} />
            </Div>
          </Div>
        </Div>
      </Div>

      <Div ph='l' className='rating-item-container'>
        <Skeleton width={200} height={30} />
      </Div>

      <Div className='other-details'>
        <Div flex mb='l'>
          <Div>
            <Skeleton width={100} height={10} />
            <Skeleton width={100} height={10} />
          </Div>
          <Div>
            <Skeleton width={100} height={10} />
            <Skeleton width={100} height={10} />
          </Div>
        </Div>
        <Div flex>
          <Div>
            <Skeleton width={100} height={10} />
            <Skeleton width={100} height={10} />
          </Div>
        </Div>
      </Div>
    </Div>
  ) : null;
};

export default SkeletonHirerViewRatingCard;
