import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Div } from '../../framework';
import { RootState } from '../../store/store';

import './ApplicantCard.scss';

type Props = {
  children: React.ReactNode;
  isRecommended: boolean;
};

const SkeletonApplicantCard: FC<Props> = ({ children, isRecommended }) => {
  const { loadStack } = useSelector((state: RootState) => state.app);

  return loadStack.includes('job/applicant/list') ||
    loadStack.includes('activity/role/talent/get') ||
    loadStack.includes(`job/applicant/list?isRecommended=${isRecommended}`) ? (
    <Div className='skeleton-applicant-card'>
      <Div flex className='header'>
        <Div className='heading'>
          <Div className='title'>
            <Skeleton
              width={100}
              height={20}
              baseColor='#ebebeb33'
              highlightColor='#f5f5f533'
            />
          </Div>
          <Div className='subtitle'>
            <Skeleton
              width={200}
              height={10}
              baseColor='#ebebeb33'
              highlightColor='#f5f5f533'
            />
          </Div>
        </Div>
        <Div flex className='status'>
          <Skeleton
            width={30}
            height={20}
            baseColor='#ebebeb33'
            highlightColor='#f5f5f533'
          />
        </Div>
      </Div>
      <Div flex className='profile'>
        <Div className='photo'>
          {/* <img src='https://heycastme-talent-media-dev.s3.ap-southeast-1.amazonaws.com/isaackon/AEqsOIr5TU.jpg' /> */}
        </Div>
        <Div className='info'>
          <Div flex className='gallery'>
            <Div className='image-container' />
            <Div className='image-container' />
            <Div className='image-container' />
          </Div>
          <Div className='experiences'>
            <Div className='experience'>
              <Skeleton width={200} height={10} />
              <Skeleton width={100} height={10} />
            </Div>
            <Div className='experience'>
              <Skeleton width={200} height={10} />
              <Skeleton width={100} height={10} />
            </Div>
            <Div className='experience'>
              <Skeleton width={200} height={10} />
              <Skeleton width={100} height={10} />
            </Div>
            <Div className='experience'>
              <Skeleton width={200} height={10} />
              <Skeleton width={100} height={10} />
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className='action' flex>
        <Div>
          <Skeleton width={70} height={15} />
        </Div>
        <Div>
          <Skeleton width={70} height={15} />
        </Div>
      </Div>
    </Div>
  ) : (
    <>{children}</>
  );
};

export default SkeletonApplicantCard;
