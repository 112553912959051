import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import { Div } from '../../framework';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';
import { useJobService } from '../../service/job.service';
import { Role } from '../../helper/type';
import { removeRole } from '../../store/hirer.slice';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;

type ModalContext = { role: Role };

const DeleteRoleModal: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jobService = useJobService();
  const { modal } = useSelector((state: RootState) => state.app);
  const { context } = modal.config as {
    context: ModalContext;
  };

  const { role } = context;

  const handleDeleteRole = async () => {
    const { projectId, id: roleId } = role;
    await jobService.deleteRole({ roleId });
    dispatch(removeRole({ projectId, roleId }));

    history.push(`/hirer/project/${projectId}`);
    dispatch(closeModal());
  };

  return (
    <Div className='hirer-delete-project-modal'>
      <Title level={4}>Confirm delete role?</Title>
      <Div mb='l'>
        <Div>
          Are you sure you want to delete role{' '}
          <span style={{ fontWeight: 600 }}>{role.name}</span>?
        </Div>
      </Div>
      <Div mb='m'>
        <Button
          block
          type='primary'
          danger
          onClick={handleDeleteRole}
          style={{ marginBottom: '0.75rem' }}
        >
          Confirm delete
        </Button>
        <Button block onClick={() => dispatch(closeModal())}>
          No, let me reconsider
        </Button>
      </Div>
    </Div>
  );
};

export default DeleteRoleModal;
