import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { WarningTwoTone } from '@ant-design/icons';
import { RootState } from '../../store/store';
import { Div } from '../../framework';
import { closeModal } from '../../store/app.slice';
import { useHirerService } from '../../service/hirer.service';
import { setCompanyUsers } from '../../store/hirer.slice';
import { HirerUser } from '../../helper/type';

export type ModalContext = Pick<HirerUser, 'hirerId' | 'userId'>;

const { Title } = Typography;

const DeleteHirerUserModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const hirerService = useHirerService();

  const { companyUsers } = useSelector((state: RootState) => state.hirer);
  const { modal } = useSelector((state: RootState) => state.app);
  const { context = {} } = modal.config;
  const { hirerId, userId } = context as ModalContext;

  const handleDeleteHirerUser = async () => {
    await hirerService.updateUser({ hirerId, userId, isDeleted: true });

    dispatch(
      setCompanyUsers(companyUsers.filter((user) => user.userId !== userId))
    );
    closeSelf();
  };

  const closeSelf = () => {
    dispatch(closeModal());
  };

  return (
    <Div className='delete-hirer-user-modal'>
      <Div style={{ textAlign: 'center' }} pt='m'>
        <Title level={3}>
          <Div>
            <WarningTwoTone twoToneColor='red' />
          </Div>
          Delete user?
        </Title>
      </Div>

      <Paragraph style={{ textAlign: 'center' }}>
        This action cannot be undone.
      </Paragraph>

      <Div ph='xl' mt='xxl'>
        <Button
          type='primary'
          size='large'
          block
          onClick={handleDeleteHirerUser}
        >
          YES, delete
        </Button>
        <Button
          type='link'
          size='large'
          block
          onClick={closeSelf}
          style={{ marginTop: '1rem' }}
        >
          Cancel
        </Button>
      </Div>
    </Div>
  );
};

export default DeleteHirerUserModal;
