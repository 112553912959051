import React, { FunctionComponent } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  AddApplicantViewCountPayload,
  useActivityService,
} from '../../service/activity.service';

import './MediaGalleryModal.scss';

const MediaGalleryModal: FunctionComponent = () => {
  const { modal } = useSelector((state: RootState) => state.app);
  const { context = {} } = modal.config;
  const activityService = useActivityService();

  type HandleVideoPlayContext = {
    name: string;
    payload: unknown;
  };

  const handleVideoPlay = async () => {
    if (context.handleVideoPlay) {
      const handlerContext = context.handleVideoPlay as HandleVideoPlayContext;
      const handlers: Record<string, () => Promise<unknown>> = {
        addApplicantViewCount: async () => {
          await activityService.addApplicantViewCount(
            handlerContext.payload as AddApplicantViewCountPayload,
            'video'
          );
        },
      };
      await handlers[handlerContext.name]?.call(null);
    }
  };

  const itemsWithVideo: ReactImageGalleryItem[] =
    (context.gallery as Array<ReactImageGalleryItem & { type: string }>).map(
      (item) => {
        return item.type === 'video'
          ? {
              ...item,
              renderItem: () => (
                <div className='video-wrapper'>
                  <video
                    width='100%'
                    height='auto'
                    controls
                    controlsList='nofullscreen'
                    onPlay={handleVideoPlay}
                  >
                    <source src={item.original} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ),
            }
          : item;
      }
    ) || [];

  return (
    <div className='media-gallery-modal'>
      <ImageGallery
        showPlayButton={false}
        thumbnailPosition='top'
        lazyLoad
        items={itemsWithVideo}
        startIndex={context.index as number}
        showNav={window.screen.width > 1000}
      />
    </div>
  );
};

export default MediaGalleryModal;
