import React, { forwardRef } from 'react';
import { BoxUtil, BoxUtilProps } from '../BoxUtil/BoxUtil';
import {
  FlexChildUtil,
  FlexChildUtilProps,
} from '../FlexChildUtil/FlexChildUtil';

export type DivProps = BoxUtilProps &
  FlexChildUtilProps & {
    ref?: React.Ref<HTMLDivElement>;
  };

export const Div = forwardRef<HTMLDivElement, DivProps>((props, ref) => {
  const { children, ...attr } = props;

  return (
    <BoxUtil {...attr}>
      <FlexChildUtil>
        <div ref={ref}>{children}</div>
      </FlexChildUtil>
    </BoxUtil>
  );
});
Div.displayName = 'Div';
