import React from 'react';

const BackSvg = () => (
  <svg
    width='14'
    height='24'
    viewBox='0 0 14 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='14' height='24' fill='#F5F5F5' />
    <g id='Desktop View_1 1'>
      <g id='Clip path group'>
        <mask
          id='mask0_0_1'
          maskUnits='userSpaceOnUse'
          x='-256'
          y='-6839'
          width='1920'
          height='9192'
        >
          <g id='clip-Desktop_View_1'>
            <path
              id='Vector'
              d='M1664 -6839H-256V2353H1664V-6839Z'
              fill='white'
            />
          </g>
        </mask>
        <g mask='url(#mask0_0_1)'>
          <g id='Desktop View_1'>
            <path
              id='Path 5'
              d='M-256 -1121H1664V283H-256V-1121Z'
              fill='white'
            />
            <g id='Group 5'>
              <path
                id='Icon ionic-ios-arrow-back'
                d='M4.071 11.8063L13.008 2.87631C13.3241 2.55925 13.5014 2.12958 13.5007 1.68184C13.5001 1.23409 13.3216 0.804951 13.0045 0.488813C12.6874 0.172675 12.2578 -0.00456081 11.81 -0.00390443C11.3623 -0.00324806 10.9331 0.175247 10.617 0.492313L0.492002 10.6063C0.186085 10.9134 0.010293 11.3266 0.00116731 11.76C-0.00795843 12.1934 0.150285 12.6136 0.443002 12.9333L10.61 23.1263C10.9261 23.4434 11.3553 23.6219 11.803 23.6225C12.2508 23.6232 12.6804 23.4459 12.9975 23.1298C13.3146 22.8137 13.4931 22.3845 13.4937 21.9368C13.4944 21.489 13.3171 21.0594 13.001 20.7423L4.071 11.8063Z'
                fill='black'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id='clip0_0_1'>
        <rect
          width='1920'
          height='9192'
          fill='white'
          transform='translate(-256 -6839)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default BackSvg;
