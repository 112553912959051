import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import { RootState } from '../../store/store';
import { Div } from '../../framework';
import { activityActionType } from '../../helper/constants';
import { Activity } from '../../helper/type';
import { closeModal } from '../../store/app.slice';
import { setActivities, setApplicantStatus } from '../../store/hirer.slice';
import { useJobService } from '../../service/job.service';
import {
  ConfirmApplicantPayload,
  useActivityService,
} from '../../service/activity.service';

import './UpdateApplicantStatusModal.scss';

export type UpdateApplicantStatusModalContext = {
  talentName: string;
  status: 'shortlisted' | 'confirmed';
} & ConfirmApplicantPayload;

const { Title } = Typography;
const UpdateApplicantStatusModal: FunctionComponent = () => {
  const jobService = useJobService();
  const activityService = useActivityService();
  const dispatch = useDispatch();
  const { modal } = useSelector((state: RootState) => state.app);
  const { context = {} } = modal.config;

  const { talentName, status, ...activityContext } =
    context as UpdateApplicantStatusModalContext;
  const { roleId, talentId, roleName, applicationId, hirerId, projectTitle } =
    activityContext;

  const handleStatusChange = async (confirmChange: boolean) => {
    if (!confirmChange) {
      dispatch(closeModal());
      return;
    }

    if (status === 'confirmed') {
      await activityService.confirmApplicant({
        ...activityContext,
        roleId,
        talentId,
        roleName,
        talentName,
      });
    } else if (status === 'shortlisted') {
      await activityService.createActivity({
        actionType: activityActionType.hirerShortlistApplicant,
        hirerId,
        roleId,
        talentId,
        applicationId,
        projectTitle,
        roleName,
      });
    }

    const applicantStatusPayload = { roleId, talentId, status };
    await jobService.updateApplicantStatus(applicantStatusPayload);
    const { data: result } = await activityService.listRoleTalentActivity({
      roleId,
      talentId,
    });

    dispatch(setActivities({ roleId, talentId, activities: result }));
    dispatch(setApplicantStatus(applicantStatusPayload));
    dispatch(closeModal());
  };

  const renderConfirmBody = () => (
    <>
      Are you sure you want to confirm <b>{talentName}</b> for <b>{roleName}</b>
      ? Once you&apos;ve confirmed the talent, the talent would be immediately
      notified about the confirmation.
    </>
  );

  const renderShortlistBody = () => (
    <>
      Are you sure you want to shortlist <b>{talentName}</b> for{' '}
      <b>{roleName}</b>? Once you&apos;ve shortlisted the talent, the talent
      would be immediately notified.
    </>
  );

  return (
    <Div className='update-applicant-status-modal'>
      <Title level={4}>
        {status === 'confirmed'
          ? 'Confirm applicant to shoot?'
          : 'Shortlist this applicant?'}
      </Title>
      <Div mb='l'>
        {status === 'confirmed' ? renderConfirmBody() : renderShortlistBody()}
      </Div>
      <Div mb='m'>
        <Button block type='primary' onClick={() => handleStatusChange(true)}>
          {status === 'confirmed' ? 'Confirm' : 'Shortlist'}
        </Button>
      </Div>
      <Button block onClick={() => handleStatusChange(false)}>
        No, let me reconsider
      </Button>
    </Div>
  );
};

export default UpdateApplicantStatusModal;
