import React from 'react';
import classNames from 'classnames';

import './BoxUtil.scss';

type SizingProps = 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type BoxUtilProps = React.HTMLAttributes<Record<string, unknown>> & {
  m?: SizingProps;
  mt?: SizingProps;
  mr?: SizingProps;
  mb?: SizingProps;
  ml?: SizingProps;
  mv?: SizingProps;
  mh?: SizingProps;
  p?: SizingProps;
  pt?: SizingProps;
  pr?: SizingProps;
  pb?: SizingProps;
  pl?: SizingProps;
  pv?: SizingProps;
  ph?: SizingProps;
  flex?: boolean;
};

export const BoxUtil: React.FC<BoxUtilProps> = (props) => {
  const {
    children,
    m,
    mt,
    mr,
    mb,
    ml,
    mv,
    mh,
    p,
    pt,
    pr,
    pb,
    pl,
    pv,
    ph,
    flex,
    className,
    style,
    ...attr
  } = props;
  const element = children as React.ReactElement;

  const renderClassName = (): string => {
    return classNames(element.props.className, className, 'af-box', {
      [`--m-${m}`]: !!m,
      [`--m-top-${mt}`]: !!mt,
      [`--m-right-${mr}`]: !!mr,
      [`--m-bottom-${mb}`]: !!mb,
      [`--m-left-${ml}`]: !!ml,
      [`--m-vertical-${mv}`]: !!mv,
      [`--m-horizontal-${mh}`]: !!mh,
      [`--p-${p}`]: !!p,
      [`--p-top-${pt}`]: !!pt,
      [`--p-right-${pr}`]: !!pr,
      [`--p-bottom-${pb}`]: !!pb,
      [`--p-left-${pl}`]: !!pl,
      [`--p-vertical-${pv}`]: !!pv,
      [`--p-horizontal-${ph}`]: !!ph,
      [`--flex`]: flex,
    });
  };

  const renderStyle = (): React.StyleHTMLAttributes<{}> => ({
    ...element.props.style,
    ...style,
  });

  return React.cloneElement(element, {
    className: renderClassName(),
    style: renderStyle(),
    ...attr,
  });
};
