import React, { useEffect, FC } from 'react';
import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { clearError } from '../../store/app.slice';
import { Div } from '../../framework';

const { Text } = Typography;

type Props = {
  requestKey: string;
};

const RequestError: FC<Props> = ({ requestKey }) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state: RootState) => state.app);
  const requestError = error[requestKey];

  useEffect(() => {
    return () => {
      dispatch(clearError({ requestKey }));
    };
  }, []);

  return requestError && requestError.code === 'INTERNAL_SERVER_EXCEPTION' ? (
    <Div pt='s'>
      <Text type='danger'>
        Oops! Something went wrong. This shouldn&apos;t happen, our team is
        informed and we are working to fix this issue. You could follow-up with
        our team with the provided error ID: {requestError.id}.
      </Text>
    </Div>
  ) : null;
};

export default RequestError;
