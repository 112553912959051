import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { Button, Dropdown, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Div } from '../../framework';

import './RatingComment.scss';

type Props = {
  authorName: string;
  profilePic?: string;
  commentDate: string;
  comment: string;
  editable?: boolean;
  onEdit?: (editedComment: string) => void;
  onDelete?: () => void;
  highlight?: boolean;
};

const { Paragraph } = Typography;

const RatingComment: FC<Props> = ({
  authorName,
  comment,
  commentDate,
  profilePic,
  editable,
  onEdit,
  onDelete,
  highlight,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editText, setEditText] = useState(comment);

  const handleSaveEdit = () => {
    onEdit && onEdit(editText);
    setIsEditMode(false);
  };

  return (
    <Div
      ref={(el) => {
        if (highlight) {
          el?.scrollIntoView();
          window.scrollBy({ top: -80 }); // top menu height
        }
      }}
      className={`div-rating-comment ${highlight ? '--highlight' : ''}`}
    >
      <Div flex className='author-container'>
        <img src={profilePic} className='author-profile-pic' />
        <Div>
          <Div className='author-name'>{authorName}</Div>
          <Div className='comment-date'>
            {format(new Date(commentDate), 'dd MMM yyyy')}
          </Div>
        </Div>
        {editable && (
          <Div className='action-dropdown'>
            <Dropdown
              trigger={['click']}
              placement='bottomRight'
              overlayClassName='rating-comment-dropdown-overlay'
              menu={{
                items: [
                  {
                    label: 'Edit',
                    key: 'edit',
                    onClick: () => setIsEditMode(true),
                  },
                  { label: 'Delete', key: 'delete', onClick: onDelete },
                ],
              }}
            >
              <a className='dropdown-btn'>
                <EllipsisOutlined />
              </a>
            </Dropdown>
          </Div>
        )}
      </Div>

      {isEditMode ? (
        <Div className='edit-comment-form'>
          <TextArea
            rows={4}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
          />
          <Div flex className='action-buttons-wrapper'>
            <Button
              type='link'
              onClick={() => {
                setIsEditMode(false);
                setEditText(comment);
              }}
            >
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={handleSaveEdit}
              className='btn-save-edit'
              disabled={editText.length === 0}
            >
              Save
            </Button>
          </Div>
        </Div>
      ) : (
        <Div className='comment'>
          {comment
            .trim()
            .split('\n')
            .map((cmt, idx) => (
              <Paragraph
                key={`${authorName}-${commentDate}-${idx}`}
                className='mb-0'
              >
                {cmt}
                <br />
              </Paragraph>
            ))}
        </Div>
      )}
    </Div>
  );
};

export default RatingComment;
