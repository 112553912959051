import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import type { FunctionComponent } from 'react';
import './Flyout.scss';

type FlyoutItemProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  to?: string | { pathname: string; search?: string; state?: any };
  href?: string;
  target?: string;
  children: React.ReactNode;
};

type FlyoutProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const flyoutRoot =
  typeof window !== 'undefined' && document.getElementById('flyout');

const FlyoutItem: FunctionComponent<FlyoutItemProps> = ({
  onClick,
  to,
  href,
  target,
  children,
}) => {
  if (to) {
    return (
      <Link className='component-flyout-item' to={to}>
        <>{children}</>
      </Link>
    );
  }

  if (href) {
    return (
      <a className='component-flyout-item' href={href} target={target}>
        {children}
      </a>
    );
  }

  return (
    <button className='component-flyout-item' onClick={onClick}>
      {children}
    </button>
  );
};

const Flyout: FunctionComponent<FlyoutProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  useEffect(() => {
    setIsFlyoutOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsFlyoutOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return flyoutRoot && isOpen
    ? createPortal(
        <div
          className={`component-flyout ${isFlyoutOpen ? '--open' : ''}`.trim()}
        >
          <div className='overlay' onClick={handleClose} />
          <div className='panel'>
            <div className='panel-header'>
              <button onClick={handleClose}>
                <CloseOutlined />
              </button>
            </div>
            {children}
          </div>
        </div>,
        flyoutRoot
      )
    : null;
};

export { Flyout, FlyoutItem };
