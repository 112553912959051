import React, { FC } from 'react';

type Props = {
  size: number;
};

const TikTokSvg: FC<Props> = ({ size = 40 }) => (
  <svg viewBox='0 0 1024 1024' version='1.1' width={size} height={size}>
    <path
      d='M270.4 633.376c5.024-41.408 22.016-64.608 54.016-88.352 45.76-32.192 102.976-13.984 102.976-13.984V423.04c13.888-0.352 27.84 0.448 41.568 2.464v138.976s-57.184-18.208-102.976 13.984c-32 23.776-48.96 46.944-53.984 88.352-0.16 22.496 4.064 51.872 23.52 77.312a189.408 189.408 0 0 1-14.72-8.448c-42.784-28.736-50.56-71.84-50.368-102.304zM705.152 223.328c-31.488-34.528-43.424-69.376-47.712-93.856h39.616s-7.904 64.192 49.696 127.328l0.768 0.832a228.48 228.48 0 0 1-42.368-34.304zM896 321.184v136.192s-50.56-1.984-88-11.52c-52.224-13.28-85.824-33.696-85.824-33.696s-23.2-14.592-25.056-15.584v281.248c0 15.648-4.288 54.752-17.376 87.36-17.056 42.688-43.392 70.72-48.256 76.416 0 0-32 37.856-88.544 63.36-50.944 23.008-95.68 22.4-109.024 23.008 0 0-77.344 3.04-146.912-42.176a291.968 291.968 0 0 1-41.92-33.696l0.32 0.256c69.6 45.216 146.88 42.144 146.88 42.144 13.408-0.576 58.112 0 109.056-23.008 56.448-25.504 88.544-63.36 88.544-63.36 4.8-5.728 31.264-33.728 48.256-76.416 13.056-32.608 17.376-71.712 17.376-87.36v-281.248c1.856 1.056 25.056 15.616 25.056 15.616s33.6 20.416 85.824 33.728c37.44 9.504 88 11.52 88 11.52V317.184c17.28 3.872 32 4.928 41.6 3.968z'
      fill='#EE1D52'
    ></path>
    <path
      d='M854.4 317.216v106.688s-50.56-1.984-87.968-11.52c-52.256-13.28-85.856-33.696-85.856-33.696s-23.168-14.56-25.056-15.616v281.28c0 15.68-4.288 54.784-17.344 87.392-17.088 42.688-43.424 70.72-48.256 76.416 0 0-32.064 37.856-88.544 63.36-50.944 23.008-95.68 22.4-109.056 23.008 0 0-77.312 3.04-146.88-42.144l-0.352-0.256a265.92 265.92 0 0 1-20.736-22.496c-22.208-27.072-35.84-59.104-39.232-68.256v-0.096c-5.504-15.872-17.056-54.016-15.488-90.944 2.784-65.152 25.728-105.152 31.776-115.168a273.728 273.728 0 0 1 61.696-72.224 260.256 260.256 0 0 1 73.28-41.6 255.04 255.04 0 0 1 91.008-18.304v108s-57.184-18.144-102.976 13.984c-32 23.744-48.96 46.944-53.984 88.32-0.192 30.496 7.584 73.6 50.368 102.368a182.4 182.4 0 0 0 14.688 8.416c7.456 9.728 16.576 18.176 26.912 25.056 41.792 26.432 76.8 28.288 121.536 11.104 29.856-11.488 52.32-37.344 62.72-66.016 6.56-17.92 6.496-35.936 6.496-54.592V129.472h104.16c4.32 24.48 16.224 59.328 47.712 93.856 12.64 13.024 26.88 24.544 42.4 34.304 4.576 4.736 28.032 28.192 58.112 42.592 15.552 7.456 31.968 13.12 48.864 16.992z'
      fill='#000000'
    ></path>
    <path
      d='M143.68 728.224v0.096l2.56 6.976c-0.32-0.8-1.28-3.296-2.56-7.072z'
      fill='#69C9D0'
    ></path>
    <path
      d='M336.416 441.344a260.256 260.256 0 0 0-73.312 41.6 273.728 273.728 0 0 0-61.664 72.384c-6.08 9.984-28.992 50.016-31.776 115.168-1.6 36.928 9.984 75.04 15.488 90.912v0.128c3.456 9.056 17.024 41.088 39.232 68.224 6.464 7.872 13.376 15.392 20.704 22.496a284.8 284.8 0 0 1-62.304-55.968c-22.016-26.848-35.584-58.56-39.136-67.872a1.6 1.6 0 0 1 0-0.224v-0.096c-5.504-15.872-17.12-54.016-15.488-90.976 2.784-65.152 25.696-105.152 31.776-115.2a273.408 273.408 0 0 1 61.664-72.352 259.84 259.84 0 0 1 73.312-41.6 259.456 259.456 0 0 1 51.712-14.592 268.032 268.032 0 0 1 80.864-1.12v30.784a255.072 255.072 0 0 0-91.072 18.304z'
      fill='#69C9D0'
    ></path>
    <path
      d='M657.408 129.472h-104.192v530.24c0 18.624 0 36.608-6.464 54.56-10.496 28.672-32.896 54.528-62.72 66.016-44.8 17.248-79.776 15.36-121.536-11.104a112.416 112.416 0 0 1-26.976-24.96c35.552 18.176 67.392 17.856 106.88 2.688 29.76-11.488 52.192-37.376 62.72-66.048 6.528-17.92 6.432-35.904 6.432-54.528V96h143.872s-1.6 13.184 1.984 33.472zM854.4 287.712v29.504a233.28 233.28 0 0 1-48.768-16.96c-30.08-14.4-53.536-37.856-58.112-42.624 5.312 3.36 10.816 6.4 16.512 9.12 36.576 17.504 72.64 22.72 90.368 20.96z'
      fill='#69C9D0'
    ></path>
  </svg>
);

export default TikTokSvg;
