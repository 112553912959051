import { activityActionType } from '../constants';
import { Activity } from '../type';

const getApplicationViewCount = (activities: Activity[]) => {
  const profileView = [
    activityActionType.viewedProfile,
    activityActionType.viewedComcard,
  ];

  return {
    profile: activities.filter((act) => profileView.includes(act.actionType))
      .length,
    video: activities.filter(
      (act) => act.actionType == activityActionType.viewedCastingVideo
    ).length,
  };
};

export default getApplicationViewCount;
