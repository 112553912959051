export const getComposeEmailUrl = (payload: {
  recipient: string;
  subject?: string;
  body?: string;
}) => {
  const { recipient, subject = '', body = '' } = payload;
  return `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
};
