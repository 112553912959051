import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { RootState } from '../../store/store';
import { Div } from '../../framework';

import './ProjectCard.scss';

type Props = {
  children: React.ReactNode;
  loading?: boolean;
};

const SkeletonProjectCard: FC<Props> = ({ children, loading }) => {
  const { loadStack } = useSelector((state: RootState) => state.app);

  return loadStack.includes('job/project/list') || loading ? (
    <Div className='skeleton-project-card'>
      <Div className='header'>
        <Skeleton
          width={200}
          height={20}
          baseColor='#ebebeb33'
          highlightColor='#f5f5f533'
        />
        <Skeleton
          width={100}
          height={10}
          baseColor='#ebebeb33'
          highlightColor='#f5f5f533'
        />
      </Div>
      {/* <Div flex className='summary'>
        <Div className='attribute'>
          <Text className='value'>5</Text>
          <Text className='label'>Available Roles</Text>
        </Div>
        <Div className='attribute'>
          <Text className='value'>30</Text>
          <Text className='label'>Total Applicants</Text>
        </Div>
        <Div className='attribute'>
          <Text className='value'>8</Text>
          <Text className='label'>Pending Action</Text>
        </Div>
      </Div> */}
      <Div className='description'>
        <Skeleton width={300} height={10} />
        <Skeleton width={270} height={10} />
      </Div>
      <Div className='details'>
        <Div flex className='attribute'>
          <Div className='label'>
            <Skeleton width={100} height={10} />
          </Div>
          <Div className='value'>
            <Skeleton width={200} height={20} />
          </Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label date'>
            <Skeleton width={70} height={10} />
          </Div>
          <Div className='value'>
            <Div>
              <Skeleton width={120} height={20} />
            </Div>
            <Div>
              <Skeleton width={100} height={20} />
            </Div>
          </Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label'>
            <Skeleton width={80} height={10} />
          </Div>
          <Div className='value'>
            <Skeleton width={100} height={20} />
          </Div>
        </Div>
      </Div>
      <Div className='action' flex>
        <Div>
          <Skeleton width={70} height={15} />
        </Div>
        <Div>
          <Skeleton width={70} height={15} />
        </Div>
      </Div>
    </Div>
  ) : (
    <>{children}</>
  );
};

export default SkeletonProjectCard;
