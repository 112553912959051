import { isMobile } from './detect';

export const getEmailProviderUrl = (email: string, query: string) => {
  const emailDomain = email.split('@')[1]?.split('.')[0];

  if (!emailDomain) {
    return '';
  }

  const key = `${emailDomain.toLowerCase()}${
    isMobile ? '-mobile' : '-desktop'
  }`;

  const sender = encodeURIComponent('noreply@heycast.me');
  const searchQuery = encodeURIComponent(`heycast.me ${query}`);
  const yahooKeyword = encodeURIComponent(`from:noreply@heycast.me ${query}`);

  switch (key) {
    case 'gmail-desktop':
      return `https://mail.google.com/mail/u/0/#advanced-search/from=${sender}&query=${searchQuery}&isrefinement=true&fromdisplay=${sender}`;
    case 'gmail-mobile':
      return `https://mail.google.com/mail/mu/mp/860/#tl/search/${searchQuery}%20from%3A${sender}`;
    case 'yahoo-desktop':
      return `https://mail.yahoo.com/d/search/keyword=${encodeURIComponent(
        yahooKeyword
      )}`;
    case 'yahoo-mobile':
      return `https://mail.yahoo.com/m/search/keyword=${encodeURIComponent(
        yahooKeyword
      )}`;
    case 'outlook-desktop':
    case 'outlook-mobile':
    case 'hotmail-desktop':
    case 'hotmail-mobile':
      return 'https://outlook.live.com/mail/0/';
    default:
      return '';
  }
};
