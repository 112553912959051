type Params = Record<string, string | null>;

const generateSearchParamString = (params: Params): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).map(([key, val]) => {
    if (val) {
      searchParams.set(key, val);
    }
  });

  return searchParams.toString();
};

export default generateSearchParamString;
