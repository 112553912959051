import { Rule } from 'antd/lib/form';

export const passwordRules: Rule[] = [
  {
    min: 8,
    required: true,
    message: 'Password must consist at least 8 characters',
  },
  {
    pattern: /[A-Z]/,
    message: 'Password must consist at least an uppercase',
  },
  {
    pattern: /[a-z]/,
    message: 'Password must consist at least a lowercase',
  },
  {
    pattern: /[0-9]/,
    message: 'Password must consist at least a number',
  },
  {
    pattern: /\W/,
    message: 'Password must consist at least symbol',
  },
  {
    pattern: /^(?!\s).*\S$/,
    message: 'Password must NOT begin or end with space character',
  },
];

export const statusText: Record<string, string> = {
  none: 'No Status',
  shortlisted: 'Shortlisted',
  castingInvited: 'Casting Invited',
  castingScheduled: 'Casting Scheduled',
  pendingConfirmation: 'Pending Confirmation',
  confirmationAccepted: 'Confirmation Accepted',
  confirmationRejected: 'Confirmation Rejected',
  withdrawn: 'Withdrawn',
};

export const activityActionType = {
  viewedProfile: 'VIEWED_PROFILE',
  viewedComcard: 'VIEWED_COMCARD',
  viewedCastingVideo: 'VIEWED_CASTING_VIDEO',

  casting: 'CASTING',
  fitting: 'FITTING',
  scheduled: 'SCHEDULED',

  hirerCancelCasting: 'HIRER_CANCEL_CASTING',
  hirerShortlistApplicant: 'HIRER_SHORTLIST_APPLICANT',
  hirerConfirmApplication: 'HIRER_CONFIRM_APPLICATION',
  talentAcceptConfirmation: 'TALENT_ACCEPT_CONFIRMATION',
  talentRejectConfirmation: 'TALENT_REJECT_CONFIRMATION',
  talentWithdrawApplication: 'TALENT_WITHDRAW_APPLICATION',
  talentRejectCasting: 'TALENT_REJECT_CASTING',
  talentRejectFitting: 'TALENT_REJECT_FITTING',
  talentReceivePayment: 'TALENT_RECEIVE_PAYMENT',
};

export const socialMediaUrl: Record<string, string> = {
  instagram: 'https://instagram.com/',
  tiktok: 'https://tiktok.com/@',
  douyin: 'https://douyin.com/user/',
  facebook: 'https://facebook.com/',
  youtube: 'https://youtube.com/@',
  xiaohongshu: 'https://xiaohongshu.com/user/profile/',
  twitter: 'https://twitter.com/',
};

export enum InfluencerType {
  KOL = 'KOL',
  ACTOR = 'ACTOR/ACTRESS',
  OTHERS = 'OTHERS',
}

export const cancelCastingReason = {
  reschedule: 'Need to reschedule casting.',
  otherReason: 'Other reason.',
};

export const projectAllowance = [
  'OT',
  'Meal',
  'Travel/Transportation',
  'Fitting',
];
