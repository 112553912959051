import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
  ForwardRefRenderFunction,
  useEffect,
} from 'react';
import Cropper, { Area, CropperProps } from 'react-easy-crop';
import { Slider, Modal, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import './ImageCropper.scss';

const INIT_ZOOM = 1;
const ZOOM_STEP = 0.1;
const INIT_ROTATE = 0;
const ROTATE_STEP = 1;
const MIN_ROTATE = -180;
const MAX_ROTATE = 180;

type Props = {
  cropperRef?: React.Ref<Cropper>;
  image?: CropperProps['image'];
  file?: File;
  aspect?: number;
  shape?: CropperProps['cropShape'];
  grid?: CropperProps['showGrid'];
  zoom?: CropperProps['zoomWithScroll'];
  rotate?: boolean;
  minZoom?: CropperProps['minZoom'];
  maxZoom?: CropperProps['maxZoom'];
  fillColor?: CanvasFillStrokeStyles['fillStyle'];
  cropperProps?: Partial<CropperProps>;
  onCropOk?: (file: File) => void;
  onCropCancel?: () => void;
  modalTitle?: string;
};

const ImageCropper: ForwardRefRenderFunction<unknown, Props> = (props, ref) => {
  const {
    cropperRef,
    image,
    file,
    aspect = 1,
    shape,
    grid = true,
    zoom = true,
    rotate = true,
    minZoom = 1,
    maxZoom = 3,
    fillColor = 'white',
    cropperProps,
    onCropOk,
    onCropCancel,
    modalTitle,
  } = props;

  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [zoomVal, setZoomVal] = useState(INIT_ZOOM);
  const [rotateVal, setRotateVal] = useState(INIT_ROTATE);

  useImperativeHandle(ref, () => ({
    rotateVal,
    setZoomVal,
    setRotateVal,
  }));

  useEffect(() => {
    if (file) onCropChange({ x: 0, y: 0 });
  }, [file]);

  const handleMediaLoaded = useCallback(
    (mediaSize: { width: number; height: number }) => {
      const { width, height } = mediaSize;
      const ratioWidth = height * aspect;

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height });
      } else {
        setCropSize({ width, height: width / aspect });
      }
    },
    [aspect]
  );

  const handleCropUpdate = useCallback((_: unknown, croppedArea: Area) => {
    croppedArea && setCroppedArea(croppedArea);
  }, []);

  const handleConfirmCrop = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imgSource = document.querySelector(
      '.photo-manager-image-crop-media'
    ) as HTMLImageElement;

    if (!ctx || !croppedArea) return;

    const {
      width: cropWidth,
      height: cropHeight,
      x: cropX,
      y: cropY,
    } = croppedArea;

    if (rotate && rotateVal !== INIT_ROTATE) {
      const { naturalWidth: imgWidth, naturalHeight: imgHeight } = imgSource;
      const angle = rotateVal * (Math.PI / 180);

      // get container for rotated image
      const sine = Math.abs(Math.sin(angle));
      const cosine = Math.abs(Math.cos(angle));
      const squareWidth = imgWidth * cosine + imgHeight * sine;
      const squareHeight = imgHeight * cosine + imgWidth * sine;

      canvas.width = squareWidth;
      canvas.height = squareHeight;
      ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, squareWidth, squareHeight);

      // rotate container
      const squareHalfWidth = squareWidth / 2;
      const squareHalfHeight = squareHeight / 2;
      ctx.translate(squareHalfWidth, squareHalfHeight);
      ctx.rotate(angle);
      ctx.translate(-squareHalfWidth, -squareHalfHeight);

      // draw rotated image
      const imgX = (squareWidth - imgWidth) / 2;
      const imgY = (squareHeight - imgHeight) / 2;
      ctx.drawImage(
        imgSource,
        0,
        0,
        imgWidth,
        imgHeight,
        imgX,
        imgY,
        imgWidth,
        imgHeight
      );

      // crop rotated image
      const imgData = ctx.getImageData(0, 0, squareWidth, squareHeight);
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      ctx.putImageData(imgData, -cropX, -cropY);
    } else {
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, cropWidth, cropHeight);

      ctx.drawImage(
        imgSource,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
    }

    // return cropped image when click ok on modal
    if (file) {
      const { type, name } = file;
      canvas.toBlob(async (blob) => {
        if (!blob) return;
        const newFile = new File([blob], name, { type });
        onCropOk && onCropOk(newFile);
      }, type);
    }
  };

  return (
    <Modal
      title={modalTitle}
      open={Boolean(file)}
      maskClosable={false}
      onCancel={onCropCancel}
      onOk={handleConfirmCrop}
      className='image-cropper-modal'
    >
      <div className='cropper-container'>
        <Cropper
          {...cropperProps}
          ref={cropperRef}
          image={image}
          crop={crop}
          cropSize={cropSize}
          onCropChange={onCropChange}
          aspect={aspect}
          cropShape={shape}
          showGrid={grid}
          zoomWithScroll={zoom}
          objectFit='contain'
          zoom={zoomVal}
          rotation={rotateVal}
          onZoomChange={setZoomVal}
          onRotationChange={setRotateVal}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onMediaLoaded={handleMediaLoaded}
          onCropComplete={handleCropUpdate}
          classes={{ mediaClassName: 'photo-manager-image-crop-media' }}
        />
      </div>
      {zoom && (
        <section className='slider-control'>
          <Button
            type='text'
            onClick={() => setZoomVal(zoomVal - ZOOM_STEP)}
            disabled={zoomVal - ZOOM_STEP < minZoom}
          >
            <MinusOutlined />
          </Button>
          <Slider
            min={minZoom}
            max={maxZoom}
            step={ZOOM_STEP}
            value={zoomVal}
            onChange={setZoomVal}
          />
          <Button
            type='text'
            onClick={() => setZoomVal(zoomVal + ZOOM_STEP)}
            disabled={zoomVal + ZOOM_STEP > maxZoom}
          >
            <PlusOutlined />
          </Button>
        </section>
      )}
      {rotate && (
        <section className='slider-control'>
          <Button
            type='text'
            onClick={() => setRotateVal(rotateVal - ROTATE_STEP)}
            disabled={rotateVal === MIN_ROTATE}
          >
            ↺
          </Button>
          <Slider
            min={MIN_ROTATE}
            max={MAX_ROTATE}
            step={ROTATE_STEP}
            value={rotateVal}
            onChange={setRotateVal}
          />
          <Button
            type='text'
            onClick={() => setRotateVal(rotateVal + ROTATE_STEP)}
            disabled={rotateVal === MAX_ROTATE}
          >
            ↻
          </Button>
        </section>
      )}
    </Modal>
  );
};

export default memo(forwardRef(ImageCropper));
