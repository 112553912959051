import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const PasswordHint = () => {
  return (
    <Text type='secondary'>
      In order to protect your account, your password must consist at least:
      <ul>
        <li>8 characters</li>
        <li>an uppercase</li>
        <li>a lowercase</li>
        <li>a number</li>
        <li>a special character</li>
        <li>DO NOT begin or end with space character</li>
      </ul>
    </Text>
  );
};

export default PasswordHint;
