import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useEffect, useState } from 'react';
import { useJobService } from '../service/job.service';
import { JobHistory } from '../helper/type';
import { setApplications } from '../store/talent.slice';

type UseApplicationsOptions = {
  initLoadAll?: boolean;
  type: 'current' | 'past';
  withdraw: boolean;
};

export const useApplications = ({
  initLoadAll = false,
  type,
  withdraw,
}: UseApplicationsOptions) => {
  const dispatch = useDispatch();
  const { mappedPastApplications } = useSelector(
    (state: RootState) => state.talent
  );
  const jobService = useJobService();
  const [fetchKey, setFetchKey] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const loadApplications = async () => {
    if (fetchKey === '') return;

    setIsFetching(true);
    const res: { data: JobHistory[]; key?: string } =
      await jobService.getApplicationsByTalent({ type, withdraw }, fetchKey);
    const data = Object.values(mappedPastApplications).concat(res.data);
    setFetchKey(res.key);
    setIsFetching(false);

    dispatch(setApplications({ applications: data, type }));
  };

  const loadAllApplications = async () => {
    let _fetchKey: string | undefined = undefined;
    let data: JobHistory[] = [];
    setIsFetching(true);
    do {
      const res: { data: JobHistory[]; key?: string } =
        await jobService.getApplicationsByTalent({ type, withdraw }, _fetchKey);
      data = data.concat(res.data);
      _fetchKey = res.key;
    } while (_fetchKey);
    setIsFetching(false);

    dispatch(setApplications({ applications: data, type }));
  };

  useEffect(() => {
    if (initLoadAll) {
      loadAllApplications();
      return;
    }

    if (!Object.keys(mappedPastApplications).length) {
      loadApplications();
    }
  }, []);

  return { mappedPastApplications, isFetching, loadApplications };
};
